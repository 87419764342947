.navigationWrap {
  padding-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 26px;
  li {
    padding: 10px 0px;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.5px;
    color: rgba(19, 28, 49, 0.5);
    cursor: pointer;
    &.hit {
      position: relative;
      font-weight: 700;
      color: #131c31;
      &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        height: 2px;
        background: #131c31;
      }
    }
  }
}
:global(.pc) .navigationWrap {
  li {
    padding: 10px 7px;
  }
}
