.container {
  min-height: 100vh;
  background-color: #f6f7f9;
  // padding: 0 20px;
}
.contentWrap {
  padding: 0 20px;
}
h3.title {
  padding: 32px 0;
  display: flex;
  align-items: center;
  img {
    width: 21px;
    height: 21px;
    cursor: pointer;
  }
  span {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.5px;
    color: #232323;
    padding: 10px;
  }
}
:global(.pc) .title span {
  font-size: 24px;
  line-height: 29px;
}

.selectWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 44px;
  margin-bottom: 32px;
  .selectBtn {
    display: flex;
    align-items: center;
    height: 100%;
    gap: 6px;
    cursor: pointer;
    .customCheckbox {
      background: #ffffff;
      border: 1.5px solid #e5e5e5;
      border-radius: 3px;
      width: 21px;
      height: 21px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      &.hit {
        background: #3559ed;
        border: 1.5px solid #3559ed;
      }
      img {
        width: 12px;
        height: 9px;
      }
    }
    span {
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      display: flex;
      color: #131c31;
    }
  }
  .deleteBtn {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    height: 100%;
    color: #131c31;
    cursor: pointer;
  }
}
.pickedProductList {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 36px;
  padding-bottom: 100px;
  min-height: 500px;
}
:global(.pc) .pickedProductList {
  grid-template-columns: repeat(2, 1fr);
}
.productBox {
  cursor: pointer;
  .thumbnailWrap {
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    .checkboxWrap {
      position: absolute;
      top: 14px;
      left: 14px;
      width: 21px;
      height: 21px;
      z-index: 1;
      background: #ffffff;
      border: 1.5px solid #e5e5e5;
      border-radius: 3px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      &.hit {
        background: #3559ed;
        border: 1.5px solid #3559ed;
      }
      img {
        width: 12px;
        height: 9px;
      }
    }
    .soldoutBox {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
      border: 1px solid #e5e5e5;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 400;
      font-size: 32px;
      line-height: 38px;
      letter-spacing: -0.5px;
      color: #ffffff;
    }
    .thumbnail {
      width: 100%;
      aspect-ratio: 1/1;
      object-fit: cover;
    }
  }
  .productInfoWrap {
    padding-top: 10px;
    .productName {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.5px;
      color: #232323;
      padding: 10px;
    }
    .priceWrap {
      padding: 5px 10px;
      display: flex;
      align-items: flex-end;
      gap: 4px;
      .discountPrice {
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        color: #131c31;
      }
      .price {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        text-decoration-line: line-through;
        color: #abb1bb;
      }
    }
    .score {
      display: flex;
      align-items: center;
      padding: 2px 10px;
      img {
        width: 22px;
        height: 22px;
      }
      span {
        font-weight: 400;
        font-size: 17px;
        line-height: 20px;
        letter-spacing: -0.5px;
        color: #000000;
      }
    }
  }
}
