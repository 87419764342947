.container {
  position: relative;
  &.isDesktop {
    .appBtnWrap {
      bottom: 0.5%;
      gap: 30px;
      img {
        width: calc(100% / 2 - 15px);
      }
    }
  }
  img {
    width: 100%;
  }
  .appBtnWrap {
    position: absolute;
    bottom: 0.35%;
    left: 10%;
    right: 10%;
    display: flex;
    gap: 13px;
    img {
      cursor: pointer;
      width: calc(100% / 2 - 6.5px);
    }
  }
}
