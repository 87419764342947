.container {
  background-color: #f6f7f9;
  padding: 0 21px 160px;
  &.isMobile {
    padding: 0;
    .contentWrap {
      gap: 0;
      padding: 0 21px;
      .stepTitleWrap {
        padding: 21px;
        width: 100%;
        transform: translateX(-21px);
        background-color: #fff;
      }
      .mainWrap {
        gap: 0;
        .orderTitleWrap {
          padding: 30px 21px 12px 21px;
          width: 100%;
          transform: translateX(-21px);
          background: #fff;
        }
        .leftWrap,
        .rightWrap {
          .addrForm {
            margin: 21px 0;
          }
        }
      }
    }
  }
  .contentWrap {
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;
    max-width: 100%;
    margin: 0 auto;
    .prevWrap {
      padding: 18px 21px;
      width: 100%;
      transform: translateX(-21px);
      background: #fff;
      img {
        width: 24px;
        height: 24px;
      }
    }
    .stepTitleWrap {
      padding: 21px 0;
      display: flex;
      justify-content: space-between;
      & > span {
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
      }
      .stepWrap {
        display: flex;
        align-items: center;
        gap: 8px;
        & > span {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #999;
        }
        & > div {
          position: relative;
          content: '';
          width: 8px;
          height: 8px;
          background: #d9d9d9;
          border-radius: 50%;
        }
        .hitStep {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 16px;
          height: 16px;
          font-weight: 500;
          font-size: 10px;
          color: #fff;
          background: #131c31;
        }
      }
    }
    .mainWrap {
      display: flex;
      gap: 16px;
      &.isMobile {
        flex-direction: column-reverse;
        .leftWrap,
        .rightWrap {
          width: 100%;
        }
      }
      .deliveryTitleWrap {
        justify-content: space-between;
        & > div:first-child {
          display: flex;
          gap: 4px;
        }
      }
      .orderTitleWrap {
        padding: 30px 0 12px 0;
        display: flex;
        align-items: center;
        gap: 4px;
        img {
          width: 20px;
          height: 20px;
          aspect-ratio: 1/1;
        }
        span {
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
        }
        .customCheckBoxWrap {
          display: flex;
          align-items: center;
          cursor: pointer;
          gap: 4px;
          box-sizing: border-box;
          &.hitCheck {
            .customCheckBox {
              background: transparent;
              border: 2px solid #131c31;
              &::after {
                background: url('../../assets/question/addrCheck.png');
                background-size: 16px;
                aspect-ratio: 1/1;
                width: 16px;
                height: 16px;
              }
            }
          }
          .customCheckBox {
            box-sizing: border-box;
            width: 16px;
            height: 16px;
            border: 1.5px solid #e5e5e5;
            border-radius: 50%;
            position: relative;
            &::after {
              content: '';
              display: block;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 8px;
              height: 8px;
              border-radius: 50%;
            }
          }
          span {
            font-weight: 500;
          }
        }
      }
      .addressModalWrap {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.4);
        z-index: 100;
        .addressModal {
          position: absolute;
          top: 50%;
          left: 21px;
          right: 21px;
          transform: translateY(-50%);
          .modalHeader {
            background-color: #131c31;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding-right: 11px;
            img {
              width: 24px;
              height: 24px;
            }
          }
        }
      }
      .leftWrap,
      .rightWrap {
        width: 427px;
        .addrForm {
          padding: 16px;
          display: flex;
          gap: 16px;
          flex-direction: column;
          background: #fff;
          border-radius: 10px;
          border: 1px solid #e5e5e5;
          .addrLabelWrap {
            .inputWrap {
              input {
                margin-bottom: 8px;
              }
            }
            .inputWrap:last-child {
              input {
                margin-bottom: 0;
              }
            }
          }
          .labelWrap {
            .labelTitleWrap {
              padding: 0 0 8px 0;
              & > span:first-child {
                font-weight: 500;
                font-size: 15px;
                color: #ff5656;
              }
              & > span:last-child {
                font-weight: 500;
                font-size: 14px;
                color: #666;
              }
            }
            .inputWrap {
              &.validationError {
                input {
                  border: 1px solid #ff5656;
                }
              }
            }
            .countryRightWrap {
              .countrySelectWrap {
                display: flex;
                border-radius: 5px;
                border: 1px solid #d9d9d9;
                background: #fff;
                justify-content: space-between;
                align-items: center;
                height: 48px;
                box-sizing: border-box;
                &.validationError {
                  border: 1px solid #ff5656;
                }
                p {
                  color: rgba(19, 28, 49, 0.2);
                  font-weight: 400;
                  font-size: 14px;
                  padding: 10px;
                }
                img {
                  width: 24px;
                  height: 24px;
                  margin-right: 10px;
                }
              }
              .countryListWrap {
                border: 1px solid #d9d9d9;
                border-radius: 0px 0px 8px 8px;
                div {
                  padding: 0px 16px;
                  p {
                    color: #131c31;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    padding: 10px 0;
                    border-bottom: 1px solid #f4f4f4;
                  }
                }
              }
            }
            .customCheckBoxListWrap {
              display: flex;
              gap: 8px;
              .customCheckBoxWrap {
                width: calc(100% / 2);
                height: 48px;
                padding: 0 16px;
                display: flex;
                align-items: center;
                gap: 8px;
                border-radius: 5px;
                border: 1px solid #d9d9d9;
                box-sizing: border-box;
                cursor: pointer;
                &.hit {
                  border: 2px solid #3259e6;
                  background: #edf8ff;
                  .customCheckBox {
                    background: #fff;
                    border: 1.5px solid #3259e6;
                    &::after {
                      background-color: #3259e6;
                    }
                  }
                  span {
                    color: #131c31;
                  }
                }
                span {
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 20px;
                  color: #999;
                }
                .customCheckBox {
                  box-sizing: border-box;
                  width: 16px;
                  height: 16px;
                  background: white;
                  border: 1.5px solid #e5e5e5;
                  border-radius: 50%;
                  position: relative;
                  &::after {
                    content: '';
                    display: block;
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                  }
                }
              }
            }
            .addrText {
              padding: 4px 0;
              margin: -8px 0 8px 0;
              font-weight: 400;
              font-size: 11px;
              line-height: 16px;
              color: #999;
            }
            .lastAddrText {
              margin: -8px 0 0 0;
            }
            .inputWrap {
              display: flex;
              &.addrInputWrap {
                margin-bottom: 8px;
              }
              & > input {
                padding: 10px;
                width: 100%;
                height: 48px;
                box-sizing: border-box;
                border: 1px solid #d9d9d9;
                border-radius: 5px;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
              }
              & > input::placeholder {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #999;
              }
            }
            .addressSearch {
              font-weight: 500;
              font-size: 15px;
              line-height: 18px;
              color: #e5e5e5;
              width: 106px;
              height: 48px;
              display: flex;
              justify-content: center;
              align-items: center;
              background: #131c31;
              border-radius: 0px 5px 5px 0px;
              cursor: pointer;
              flex-shrink: 0;
              position: relative;
            }
          }
        }
      }
      .rightWrap {
        width: 365px;
        .addrForm {
          margin-bottom: 24px;
        }
        .orderBtnWrap {
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 52px;
          background: #131c31;
          border-radius: 8px;
          font-weight: 600;
          font-size: 17px;
          line-height: normal;
          color: #fff;
        }
      }
    }
    .btnWrap {
      &.isMobile {
        background: #fff;
        display: flex;
        align-items: center;
        width: calc(100% + 42px);
        height: 72px;
        transform: translateX(-21px);
        .orderBtnWrap {
          width: calc(100% - 42px);
          transform: translateX(21px);
        }
      }
    }
    //모바일
    .orderBtnWrap {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 52px;
      background: #131c31;
      border-radius: 8px;
      font-weight: 600;
      font-size: 17px;
      line-height: normal;
      color: #fff;
    }
  }
}
