.contentWrap {
  width: 100%;
  aspect-ratio: 1/1;
  & > img {
    width: 100%;
    aspect-ratio: 1/0.5;
    object-fit: cover;
    flex-shrink: 0;
  }
  .textWrap {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    aspect-ratio: 1/0.5;
    h3 {
      text-align: center;
      color: #232323;
      font-size: 23px;
      font-weight: 600;
    }
    p {
      text-align: center;
      margin-top: 20px;
      color: #232323;
      font-size: 16px;
      word-break: keep-all;
    }
  }
}
