.container {
  position: fixed;
  top: 0;
  left: 50%;
  bottom: 0;
  z-index: 1000;
  width: 100%;
  max-width: 850px;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.4);
  // display: flex;
  // justify-content: center;
  // align-items: center;
  &.pc {
    min-width: 850px;
  }
  .contentWrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 672px;
    max-height: 70vh;
    padding-bottom: 64px;
    box-sizing: border-box;
    border-radius: 32px;
    background-color: white;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    top: 50%;
    left: 50%;
    width: 333px;
    height: 454px;
    max-height: fit-content;
    .closeWrap {
      position: absolute;
      right: 0;
      top: -50px;
      img {
        cursor: pointer;
        width: 40px;
        height: 40px;
      }
    }
    .topWrap {
      position: relative;
      padding: 96px 22px 40px;
      overflow: hidden;
      background: #f9f9f9;
      border-radius: 32px 32px 0 0;
      z-index: 200;
      .mobileImg {
        position: absolute;
        top: 40px;
        left: 80px;
        right: 80px;
        width: 173px;
        z-index: 1;
      }
      .pushFrame {
        position: relative;
        padding: 20px;
        display: flex;
        gap: 20px;
        align-items: center;
        background: #fff;
        border-radius: 10px;
        box-sizing: border-box;
        height: 84px;
        z-index: 2;
        .leftWrap {
          img {
            width: 44px;
            height: 44px;
          }
        }
        .textWrap {
          span {
            color: #3259e6;
          }
          span:first-child {
            font-weight: 600;
            font-size: 17px;
            line-height: 24px;
          }
          span:last-child {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            height: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-inline-box;
            -webkit-text-fill-color: var(--theme-color);
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }
        }
      }
    }
    .bottomWrap {
      padding: 40px 0 30px;
      display: flex;
      gap: 8px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      .hitTextWrap {
        span {
          font-weight: 600;
          font-size: 20px;
          line-height: 28px;
        }
        .hit {
          color: #3259e6;
        }
      }
      & > span {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #666;
      }
    }
    .btnWrap {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 52px;
      border-radius: 0 0 32px 32px;
      background: #3259e6;
      font-weight: 600;
      font-size: 17px;
      line-height: normal;
      color: #fff;
    }
  }
}
