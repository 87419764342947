.doctorSliderWrap {
  margin-bottom: 10px;
}
.doctorNameWrap {
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fbfbfb;
  border-radius: 10px;
  .doctorNameLeft {
    display: flex;
    gap: 16px;
    align-items: center;
    img {
      width: 60px;
      height: 60px;
      aspect-ratio: 1/1;
    }
    .doctorName {
      p:first-child {
        font-weight: 600;
        font-size: 17px;
        line-height: 24px;
      }
      p:last-child {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #666;
      }
    }
  }
  & > img {
    width: 24px;
    height: 24px;
  }
}
.doctorInfoWrap {
  padding: 16px 33px;
  display: flex;
  gap: 33px;
  background: #fbfbfb;
  margin-top: 4px;
  p {
    width: 26px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }
  div {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
}
