.container {
  background-color: #f4f4f4;
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  @media screen and (max-width: 850px) {
    width: 100%;
    margin: 0 auto;
  }
}
.companyInfoWrap {
  padding: 50px 20px 15px;
  .companyInfoTop {
    position: relative;
    border-bottom: 1px solid #d9d9d9;
    img {
      cursor: pointer;
      position: absolute;
      right: 0;
      bottom: 15px;
      width: 80px;
      height: 80px;
    }
    .companyPhone {
      font-weight: 500;
      font-size: 20px;
      line-height: normal;
      margin-bottom: 8px;
    }
    .companyOpenTime {
      font-weight: 300;
      font-size: 15px;
      line-height: normal;
      margin-bottom: 24px;
    }
  }
  .companyName {
    margin: 24px 0 6px 0;
    font-weight: 500;
    font-size: 20px;
    line-height: normal;
    color: #131c31;
  }
  .companyInfoLine {
    display: flex;
    gap: 4px;
    span {
      font-weight: 300;
      font-size: 15px;
      line-height: normal;
      color: #131c31;
      display: inline-block;
      padding: 6px 0 0 0;
      &.businessInfo {
        color: #475474;
        cursor: pointer;
      }
    }
  }
  .copyright {
    display: block;
    font-weight: 300;
    font-size: 15px;
    line-height: normal;
    color: #838a9b;
    margin-top: 12px;
  }
  .navigation {
    margin-top: 10px;
    height: 74px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 21px;
    li {
      position: relative;
      padding: 10px;
      font-weight: 300;
      font-size: 15px;
      line-height: 20px;
      color: #666;
      cursor: pointer;
      &::after {
        content: '';
        display: block;
        position: absolute;
        right: -11px;
        top: 50%;
        transform: translateY(-50%);
        width: 1px;
        height: 15px;
        background-color: #d9d9d9;
      }
      &:last-child::after {
        display: none;
      }
    }
  }
}

.ondocInfo {
  font-weight: 300;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  background: #131c31;
  padding: 40px 0;
}

.container.isMobile {
  .companyInfoWrap {
    padding: 24px 21px 0 21px;
    display: flex;
    flex-direction: column;
    img {
      width: 60px;
      height: 60px;
    }
    .companyInfoTop {
      display: flex;
      flex-direction: column;
      gap: 4px;
      .companyPhone {
        color: #131c31;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        margin-bottom: 0px;
      }
      .companyOpenTime {
        color: #131c31;
        font-size: 12px;
        font-weight: 300;
        line-height: 16px;
      }
    }

    .companyInfoBorder {
      background: #d9d9d9;
      height: 1px;
    }

    .companyInfoBottom {
      display: flex;
      flex-direction: column;
      .companyName {
        padding: 0;
        color: #131c31;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        margin-bottom: 0;
      }
      .companyInfoBottomContent {
        display: flex;
        flex-direction: column;
        gap: 4px;
        padding: 8px 0;
        .companyInfoLine {
          display: flex;
          gap: 4px;
          span {
            padding: 0;
            color: #131c31;
            font-size: 12px;
            font-weight: 300;
            line-height: 16px;
            &.businessInfo {
              color: #999;
              font-weight: 500;
            }
          }
        }
      }
    }
    .copyright {
      margin-top: 0;
      color: #999;
      text-align: left;
      font-size: 12px;
      font-weight: 300;
      line-height: 16px;
      padding: 0;
    }
    .navigation {
      height: 48px;
      gap: 7px;
      li {
        font-weight: 300;
        font-size: 10px;
        line-height: 13px;
        letter-spacing: -0.5px;
        color: #999;
        padding: 4px;
        &::after {
          height: 10px;
          background-color: #d9d9d9;
          right: -4px;
        }
      }
    }
  }
  .ondocInfo {
    padding: 20px 0px;
    line-height: normal;
    font-size: 10px;
    font-weight: 300;
    color: #fff;
  }
}
