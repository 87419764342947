.contentWrap {
  background: #f6f7f9;
  .title {
    text-align: center;
    padding: 42px 21px;
    &.isMobile {
      text-align: left;
    }
    span {
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      color: #232323;
      display: inline-block;
      padding: 0 10px;
    }
    img {
      width: 24px;
      height: 24px;
    }
  }
}
.editForm {
  display: flex;
  flex-direction: column;
  // background: rgba(255, 255, 255, 0.7);
  // box-shadow: 2px 5px 15px rgba(3, 133, 0, 0.15);
  // border-radius: 24px;
  padding: 0px 0px 100px;
  max-width: 333px;
  margin: 0 auto;
}
.id {
  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > span:first-child {
      display: inline-block;
      padding: 11px 0;
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      color: #131c31;
      &::after {
        content: '*';
        display: inline-block;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: #ff5656;
        margin-left: 1px;
      }
    }
    & > span:last-child {
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      text-decoration-line: underline;
      color: rgba(19, 28, 49, 0.2);
      cursor: pointer;
    }
  }
  .idInputWrap,
  .emailInputWrap {
    display: flex;
    align-items: center;
    input {
      flex-grow: 1;
      background: #ffffff;
      border: 1px solid #e5e5e5;
      border-radius: 5px;
      height: 48px;
      box-sizing: border-box;
      padding-left: 10px;
      color: #141736;
      font-weight: 400;
      font-size: 15px;
      min-width: 0;
      line-height: 18px;
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      color: #000000;
      &::placeholder {
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        color: rgba(19, 28, 49, 0.2);
      }
    }
    .idCheck,
    .emailCheck {
      height: 48px;
      background: #131c31;
      border-radius: 0px 5px 5px 0px;
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #e5e5e5;
      width: 69px;
      cursor: pointer;
      flex-shrink: 0;
    }
  }
}
.pw,
.pwCheck,
.name {
  display: flex;
  flex-direction: column;
  & > span {
    display: inline-block;
    padding: 11px 0;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #131c31;
    &::after {
      content: '*';
      display: inline-block;
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      color: #ff5656;
      margin-left: 1px;
    }
  }
  input {
    height: 48px;
    box-sizing: border-box;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #000000;
    padding-left: 10px;
    &::placeholder {
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      color: rgba(19, 28, 49, 0.2);
    }
  }
}
.tel {
  & > span {
    display: inline-block;
    padding: 11px 0;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #131c31;
    &::after {
      content: '*';
      display: inline-block;
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      color: #ff5656;
      margin-left: 1px;
    }
  }
  .telInputWrap {
    display: flex;
    align-items: center;
    input {
      min-width: 0;
      background: #ffffff;
      border: 1px solid #e5e5e5;
      border-radius: 5px;
      height: 48px;
      // text-align: center;
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      color: #000000;
      width: 100%;
      padding-left: 10px;
      &::placeholder {
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        color: rgba(19, 28, 49, 0.2);
      }
    }
    span {
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      color: #131c31;
      display: inline-block;
      padding: 0 2px;
    }
  }
  & > p {
    padding: 6px 10px 8px;
    text-align: right;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #131c31;
  }
}
.email {
  & > span {
    display: inline-block;
    padding: 11px 0;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #131c31;
    &::after {
      content: '*';
      display: inline-block;
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      color: #ff5656;
      margin-left: 1px;
    }
  }
  .emailInputWrap {
    display: flex;
    & > input {
      min-width: 0;
      box-sizing: border-box;
      background: #ffffff;
      border: 1px solid #e5e5e5;
      border-radius: 5px;
      width: calc(50% - 10px);
      padding-left: 10px;
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      color: #000000;
    }
    & > span {
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      color: #131c31;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20px;
    }
    & > div {
      display: flex;
      box-sizing: border-box;
      width: calc(50% - 10px);
      & > .customSelect {
        box-sizing: border-box;
        flex-grow: 1;
        background: #ffffff;
        border: 1px solid #e5e5e5;
        border-radius: 5px;
        position: relative;
        overflow: hidden;
        input {
          min-width: 0;
          box-sizing: border-box;
          width: 100%;
          height: 46px;
          padding-left: 10px;
          border: none;
          outline: none;
        }
        select {
          width: 100%;
          border: none;
          box-sizing: border-box;
          height: 100%;
          outline: none;
          border-left: none;
          border-right: none;
          position: absolute;
          top: 0;
          left: 0;
          appearance: none;
          padding-left: 10px;
          // display: none;
        }
        img {
          width: 24px;
          height: 24px;
          position: absolute;
          top: 50%;
          right: 5px;
          transform: translateY(-50%);
          cursor: pointer;
        }
      }
    }
  }
  .emailCheck {
    background: #131c31;
    border-radius: 0px 5px 5px 0px;
    width: 100%;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #e5e5e5;
    flex-shrink: 0;
    cursor: pointer;
    margin-top: 12px;
  }
}
.referral {
  & > span {
    display: inline-block;
    padding: 23px 0 11px 0;
    color: #131c31;
    font-size: 15px;
    font-weight: 500;
  }
  .referralInputWrap {
    display: flex;
    align-items: center;
    input {
      min-width: 0;
      background: #ffffff;
      border: 1px solid #e5e5e5;
      border-radius: 5px;
      height: 48px;
      padding-left: 10px;
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      color: #000000;
      width: 100%;
      &::placeholder {
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        color: rgba(19, 28, 49, 0.2);
      }
    }
    span {
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      color: #131c31;
      display: inline-block;
      padding: 0 2px;
    }
  }
  .referralJoinMsg {
    text-align: right;
    color: #131c31;
    font-size: 15px;
    font-weight: 500;
  }
  margin-bottom: 12px;
}
.marketing {
  & > span {
    display: inline-block;
    padding: 11px 0;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #131c31;
    &::after {
      content: '*';
      display: inline-block;
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      color: #ff5656;
      margin-left: 1px;
    }
  }
  & > ul {
    display: flex;
    li {
      width: 33%;
      height: 44px;
      flex-grow: 1;
      display: flex;
      align-items: center;
      gap: 6px;
      padding-left: 12px;
      cursor: pointer;

      .checkbox {
        width: 19px;
        height: 19px;
        box-sizing: border-box;
        border: 1.5px solid rgba(19, 28, 49, 0.2);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        &.hit {
          background: #ff5656;
          border: 1.5px solid #ff5656;
          &::after {
            background-color: white;
          }
        }
        &::after {
          content: '';
          display: block;
          background: rgba(19, 28, 49, 0.2);
          width: 8px;
          height: 8px;
          border-radius: 50%;
        }
      }
      span {
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: #131c31;
      }
    }
  }
  p {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: #131c31;
    padding: 6px 0 8px;
  }
}
.submitBtn {
  background: #141736;
  border-radius: 5px;
  height: 55px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  border: none;
  box-sizing: border-box;
  cursor: pointer;
  margin-top: 27px;
}
