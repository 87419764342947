.container {
  box-sizing: border-box;
  overflow: hidden;
  border-bottom: 1px solid #d9d9d9;
  &:first-child {
    border-top: 1px solid #131c31;
  }
  &:last-child {
    border: none;
  }
}
.askWrap {
  background: white;
  display: flex;
  gap: 20px;
  align-items: center;
  height: 68px;
  padding: 0 20px;
  cursor: pointer;
  position: relative;
  &.isOpen::after {
    width: 100%;
  }
  span {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #131c31;
    position: relative;
    z-index: 1;
  }
  // &.isOpen span {
  //   color: white;
  // }
  img {
    width: 20px;
    position: relative;
    z-index: 1;
  }
}

.answerWrap {
  background-color: #fbfbfb;
  max-height: 0;
  transition: max-height 0.5s, padding 0.5s;
  overflow: hidden;
  padding: 0 24px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #131c31;
  &.isOpen {
    padding: 20.5px 24px;
    max-height: 500px;
  }
}
