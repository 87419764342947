.container {
  // background: url('../../assets/loginBanner.png');
  // background-repeat: no-repeat;
  // background-size: cover;
  // background-position: center bottom;
  min-height: 100vh;
  box-sizing: border-box;
  background: #f6f7f9;
  &.isMobile {
    .contentWrap {
      .title {
        padding: 40px 0;
        &.nonTitle {
          padding: 0;
        }
      }
      .loginForm {
        &.nonForm {
          padding: 40px 0 80px;
        }
      }
    }
  }
}
.backBtn {
  padding: 18px 21px;
  img {
    width: 24px;
    height: 24px;
  }
}
.contentWrap {
  max-width: 333px;
  margin: 0 auto;
  .title {
    &.nonTitle {
      padding: 0;
    }
    padding: 60px 0 40px;
    text-align: center;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: #131c31;
  }
  .loginForm {
    display: flex;
    flex-direction: column;
    &.nonForm {
      padding: 40px 0 120px;
    }
    .kakaoLoginBtn {
      background: #fee500;
      border-radius: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      height: 55px;
      cursor: pointer;
      img {
        position: absolute;
        width: 48px;
        height: 48px;
        top: 50%;
        left: 14px;
        transform: translateY(-50%);
      }
      span {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #1c1c1e;
      }
    }
    .inputWrap {
      position: relative;
      margin-bottom: 10px;
      input {
        width: 100%;
        height: 55px;
        background: #fff;
        border-radius: 27.5px;
        box-sizing: border-box;
        outline: none;
        border: none;
        padding-left: 20px;
        font-weight: 400;
        font-size: 14px;
        line-height: normal;
        color: #141736;
        &::placeholder {
          font-weight: 300;
          font-size: 14px;
          line-height: normal;
          color: #999;
        }
      }
      img {
        width: 19px;
        height: 20px;
        position: absolute;
        top: 50%;
        left: 29px;
        transform: translateY(-50%);
      }
    }
    .loginBtn {
      &.nonBtn {
        background: #3259e6;
      }
      cursor: pointer;
      height: 55px;
      box-sizing: border-box;
      background: #141736;
      border-radius: 27.5px;
      border: none;
      font-weight: 600;
      font-size: 17px;
      line-height: 24px;
      color: #ffffff;
      margin-top: 11px;
    }
  }
  .menu {
    display: flex;
    justify-content: center;
    padding: 10px 0;
    margin-top: 10px;
    li {
      cursor: pointer;
      position: relative;
      width: 107px;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      color: #131c31;
      &:first-child::after {
        display: none;
      }
      &::after {
        content: 'l';
        display: block;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}

.or {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 9px;
  padding: 40px 0;
  div {
    flex-grow: 1;
    height: 1px;
    background: #d9d9d9;
  }
  span {
    font-weight: 300;
    font-size: 15px;
    line-height: 18px;
    color: #999;
  }
}
