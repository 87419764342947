.contentWrap {
  background: #f9f9f9;
  &.isDesktop {
    .titleWrap {
      padding: 21px;
    }
    .listWrap {
      padding: 21px 21px 16px;
      .listFrame {
        .leftWrap {
          img {
            height: 80px;
            aspect-ratio: 1/1;
          }
        }
      }
    }
    .btnWrap {
      background: transparent;
      padding: 0 21px;
      height: 52px;
      margin-bottom: 120px;
    }
  }
  .titleWrap {
    padding: 20px 33px;
    background: #fff;
    span {
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
    }
    span:last-child {
      color: #3259e6;
    }
  }
  .listWrap {
    padding: 21px;
    display: flex;
    gap: 8px;
    flex-direction: column;
    .listFrame {
      display: flex;
      gap: 16px;
      justify-content: space-between;
      border-radius: 10px;
      background: #fff;
      &.noFrame {
        background: #f9f9f9;
      }
      .leftWrap {
        display: flex;
        align-items: center;
        img {
          width: 80px;
          height: 96px;
          aspect-ratio: 80/96;
        }
        .textWrap {
          display: flex;
          gap: 4px;
          flex-direction: column;
          span:first-child {
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
          }
          span:last-child {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #666;
          }
        }
      }
      .rightWrap {
        padding: 0 16px 0 0;
        display: flex;
        align-items: center;
        input {
          padding: 10px;
          width: 38px;
          height: 40px;
          border-radius: 10px;
          box-sizing: border-box;
          border: 1px solid #d9d9d9;
        }
        span {
          width: 12px;
          text-align: center;
        }
      }
    }
    .eatWrap {
      display: flex;
      gap: 4px;
      align-items: center;
      border-radius: 10px;
      background: #edf8ff;
      border: 1px solid #3259e6;
      img {
        width: 80px;
        height: 60px;
      }
      & > div {
        display: flex;
        gap: 4px;
        span {
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
        }
        span:last-child {
          color: #3259e6;
        }
      }
    }
  }
  .btnWrap {
    padding: 10px 21px;
    height: 72px;
    box-sizing: border-box;
    background: #fff;
    &.noBtnWrap {
      .btn {
        background: #999;
      }
    }
    .btn {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      height: 52px;
      font-weight: 600;
      font-size: 17px;
      line-height: normal;
      color: #fff;
      background: #3259e6;
    }
  }
}
