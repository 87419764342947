.container {
  position: fixed;
  top: 0;
  left: 50%;
  bottom: 0;
  z-index: 1000;
  width: 100%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.5);
  &.isDesktop {
    max-width: 850px;
    .contentWrap {
      position: relative;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 375px;
      height: 306px;
      border-radius: 24px;
      .closeWrap {
        position: absolute;
        right: 10px;
        top: -50px;
        img {
          cursor: pointer;
          width: 40px;
          height: 40px;
        }
      }
      .mainWrap {
        padding: 30px 0;
      }
      .btnWrap {
        border-radius: 0 0 24px 24px;
      }
    }
  }
  .contentWrap {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 314px;
    box-sizing: border-box;
    border-radius: 24px 24px 0 0;
    background-color: white;
    .slideWrap {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 28px;
      img {
        width: 49px;
        height: 4px;
      }
    }
    .mainWrap {
      padding: 20px 0 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      img {
        width: 70px;
        height: 70px;
        margin-bottom: 20px;
      }
      .quesText {
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 8px;
      }
      .smallText {
        span {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #666;
        }
        .hit {
          font-weight: 500;
          color: #3259e6;
        }
      }
    }
    .btnWrap {
      padding: 0 21px;
      display: flex;
      gap: 11px;
      align-items: center;
      justify-content: center;
      height: 70px;
      background: #fff;
      box-sizing: border-box;
      .nextBtnWrap,
      .okBtnWrap {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: calc(100% / 2 - 11px);
        height: 50px;
        border-radius: 8px;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
      }
      .nextBtnWrap {
        background: #edf8ff;
        color: #3259e6;
      }
      .okBtnWrap {
        background: #3259e6;
        color: #fff;
      }
    }
  }
}
