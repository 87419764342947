.container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: red;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contentWrap {
  background-color: white;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}
.header {
  height: 70px;
  padding: 0 21px;
  display: flex;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid rgba(19, 28, 49, 0.1);
  box-sizing: border-box;
  img {
    width: 21px;
    height: 21px;
    cursor: pointer;
  }
  span {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.5px;
    color: #232323;
  }
}
.useableCouponWrap {
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  & > span {
    padding: 20px 0;
    display: flex;
    align-items: center;
    &,
    & > span {
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      color: #131c31;
    }
    & > span {
      color: #4d7eff;
      display: inline-block;
      margin: 0 2px;
    }
  }
  .useableCouponList {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
.coupon {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  height: 48px;
  gap: 7px;
  cursor: pointer;
  &.hit {
    .customCheckbox {
      background: #ff5656;
      border: 1.5px solid #ff5656;
      &::after {
        background-color: white;
      }
    }
  }
  .customCheckbox {
    width: 18px;
    height: 18px;
    border: 1.5px solid #e5e5e5;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    &::after {
      display: block;
      content: '';
      width: 8px;
      height: 8px;
      background: #e5e5e5;
      border-radius: 50%;
    }
  }
  span {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #131c31;
  }
}

.unuseableCouponWrap {
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  & > span {
    padding: 14px 0;
    display: flex;
    align-items: center;
    &,
    & > span {
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      color: #131c31;
    }
    & > span {
      color: #4d7eff;
      display: inline-block;
      margin: 0 2px;
    }
  }
  .unuseableCouponList {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .coupon {
      cursor: auto;
      span {
        color: rgba(19, 28, 49, 0.5);
      }
    }
  }
}
.couponGuideWrap {
  padding: 33.5px 21px 108px;
  background: #f6f7f9;
  margin-top: auto;
  & > h4 {
    font-weight: 700;
    font-size: 15px;
    line-height: 17px;
    display: flex;
    align-items: center;
    height: 36px;
    color: rgba(19, 28, 49, 0.5);
  }
  ul {
    display: flex;
    flex-direction: column;
    margin-top: 12.5px;
    padding: 0 6px;
    li {
      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
      letter-spacing: -0.4px;
      color: rgba(19, 28, 49, 0.5);
    }
  }
}
.submitBtn {
  position: absolute;
  left: 21px;
  right: 21px;
  bottom: 29px;
  background: #131c31;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  cursor: pointer;
}
:global(.pc) .container {
  width: 850px;
  left: 50%;
  right: unset;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.4);
  .contentWrap {
    width: 375px;
    height: 812px;
  }
}
