.contentWrap {
  background-color: #f6f7f9;
  padding: 54px 33px 40px 33px;
  &.isDesktop {
    padding: 50px 180px;
  }
}

.title {
  span {
    margin-left: 4px;
    color: #131c31;
    font-size: 15px;
    font-weight: 400;
  }
  h2 {
    margin-top: 16px;
    color: #232323;
    font-size: 24px;
    font-weight: 700;
  }
  p {
    color: #232323;
    font-size: 15px;
    font-weight: 600;
    margin-top: 8px;
  }
}

.weightForm {
  margin-top: 50px;
  .weightInputWrap {
    display: flex;
    border: none;
    border-bottom: 1px solid #e5e5e5;
    align-items: center;
    input {
      border: none;
      background-color: #f6f7f9;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding: 11px 0 11px 12px;
      flex-grow: 1;
    }
    input::placeholder {
      color: #abb1bb;
      font-size: 15px;
      font-weight: 400;
    }
    span {
      color: rgba(19, 28, 49, 0.5);
      font-size: 15px;
      font-weight: 500;
      margin: 0 10px;
    }
  }

  .targetWeightInputWrap {
    display: flex;
    border: none;
    border-bottom: 1px solid #e5e5e5;
    align-items: center;
    margin-bottom: 16px;
    input {
      border: none;
      background-color: #f6f7f9;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding: 11px 0 11px 12px;
      flex-grow: 1;
    }
    input::placeholder {
      color: #abb1bb;
      font-size: 15px;
      font-weight: 400;
    }
    span {
      color: rgba(19, 28, 49, 0.5);
      font-size: 15px;
      font-weight: 500;
      margin: 0 10px;
    }
  }

  .inputBtn {
    cursor: pointer;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 5px;
    background: #131c31;
    color: #ffffff;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: -0.5px;
    width: 100%;
    height: 54px;
    border: none;
  }

  .unableBtn {
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 5px;
    background: #999;
    color: #ffffff;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: -0.5px;
    width: 100%;
    height: 54px;
    border: none;
  }

  .inputCheck {
    margin-top: -119px;
    margin-bottom: 129px;
    color: #ff3e3e;
    font-size: 15px;
    font-weight: 400;
  }
}

.bannerWrap {
  border-radius: 5px;
  background: #131c31;
  margin-bottom: 20px;
  position: relative;
  &.isMobile {
    height: 114px;
    max-height: 114px;
    .bannerImg {
      position: absolute;
      top: 15px;
      right: 6px;
      img {
        width: 93px;
        height: 58px;
      }
    }

    .bannerTextOk {
      padding: 25px 24px 8px 22px;
      p {
        color: #fff;
        font-size: 16px;
        font-weight: 700;
      }
      span:nth-child(2) {
        color: #60e16d;
        font-size: 16px;
        font-weight: 700;
      }
      span:nth-child(3) {
        color: #fff;
        font-size: 16px;
        font-weight: 700;
      }
    }
    .bannerTextDate {
      padding: 0px 22px 25px 22px;
      white-space: nowrap;
      span:nth-child(1) {
        color: #999999;
        font-size: 15px;
        font-weight: 400;
      }
      span:nth-child(2) {
        color: #fff;
        font-size: 15px;
        font-weight: 400;
      }
    }
  }

  &.isDesktop {
    height: 114px;
    max-height: 114px;
    .bannerImg {
      position: absolute;
      top: 20px;
      right: 40px;
      img {
        width: 118px;
        height: 74px;
      }
    }
    .bannerTextOk {
      padding: 32px 40px 8px 40px;
      span:nth-child(1),
      span:nth-child(3) {
        color: #fff;
        font-size: 20px;
        font-weight: 700;
      }
      span:nth-child(2) {
        color: #60e16d;
        font-size: 20px;
        font-weight: 700;
      }
    }
    .bannerTextDate {
      margin: 0px 40px 32px 40px;
      span:nth-child(1) {
        color: #999999;
        font-size: 15px;
        font-weight: 400;
      }
      span:nth-child(2) {
        color: #fff;
        font-size: 15px;
        font-weight: 400;
      }
    }
  }
}

.challengeDetailDirectWrap {
  &.isMobile {
    margin-bottom: 50px;
  }
  cursor: pointer;
  margin-bottom: 80px;
  padding: 18px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: #fff;
  & > img {
    width: 14px;
    height: 14px;
  }
}

.weightListWrap {
  // margin-top: 131px;

  .weightListTitle {
    color: #232323;
    font-size: 20px;
    font-weight: 600;
  }
  .wieghtListSubTitle {
    margin-top: 8px;
    margin-bottom: 20px;
    color: #666;
    font-size: 15px;
    font-weight: 400;
  }
  .weightListBtnWrap {
    display: flex;
    gap: 11px;

    &.isDesktop {
      margin-bottom: 138px;
      & > button {
        cursor: pointer;
      }
      button:nth-child(1) {
        width: 156px;
        height: 153px;
        svg {
          width: 60px;
          height: 60px;
        }
        p {
          font-size: 20px;
          font-weight: 500;
        }
      }
      button:nth-child(2) {
        width: 156px;
        height: 153px;
        img {
          width: 60px;
          height: 60px;
        }
        p {
          font-size: 20px;
          font-weight: 500;
        }
      }
      button:nth-child(3) {
        width: 156px;
        height: 153px;
        svg {
          width: 60px;
          height: 60px;
        }
        p {
          font-size: 20px;
          font-weight: 500;
        }
      }
    }

    .graphBtn {
      width: 50%;
      height: 100px;
      border-radius: 5px;
      background: #fff;
      border: none;
      color: #131c31;
      font-size: 16px;
      font-weight: 500;

      p {
        margin-top: 4px;
      }
    }
    .listBtn {
      width: 50%;
      height: 100px;
      border-radius: 5px;
      background: #fff;
      border: none;
      color: #131c31;
      font-size: 16px;
      font-weight: 500;

      img {
        width: 40px;
        height: 40px;
      }

      p {
        margin-top: 4px;
      }
    }

    .pointBtn {
      width: 50%;
      height: 100px;
      border-radius: 5px;
      background: #fff;
      border: none;
      color: #131c31;
      font-size: 16px;
      font-weight: 500;
      svg {
        height: 40px;
      }
      p {
        margin-top: 4px;
      }
    }
  }
}
