.container {
  position: fixed;
  top: 0;
  left: 50%;
  bottom: 0;
  z-index: 1000;
  width: 100%;
  max-width: 850px;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.4);
  // display: flex;
  // justify-content: center;
  // align-items: center;
  &.pc {
    min-width: 850px;
  }
  .contentWrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 672px;
    max-height: 70vh;
    padding-bottom: 64px;
    box-sizing: border-box;
    border-radius: 32px;
    background-color: white;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    top: 50%;
    left: 50%;
    width: 333px;
    height: 266px;
    max-height: fit-content;
    .topWrap {
      padding: 40px 37px 30px;
      display: flex;
      gap: 8px;
      flex-direction: column;
      align-items: center;
      text-align: center;
      background: #fff;
      border-radius: 32px 32px 0 0;
      .titleWrap {
        span {
          font-weight: 600;
          font-size: 20px;
          line-height: 28px;
        }
      }
      .smallTextWrap {
        span {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #666;
        }
      }
    }
    .btnWrap {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 52px;
      border-radius: 0 0 32px 32px;
      font-weight: 600;
      font-size: 17px;
      line-height: normal;
      .noBtn,
      .okBtn {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 52px;
      }
      .noBtn {
        border-radius: 0 0 0 32px;
        background: #edf8ff;
        color: #3259e6;
      }
      .okBtn {
        border-radius: 0 0 32px 0;
        background: #3259e6;
        color: #fff;
      }
    }
  }
}
