.reviewDetailModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.7);
  .reviewDetailContent {
    position: absolute;
    top: 50%;
    left: 15px;
    right: 15px;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    .closeBtn {
      display: block;
      width: fit-content;
      margin-left: auto;
      padding: 14.5px 21px;
      font-weight: 700;
      font-size: 15px;
      line-height: 18px;
      color: #ffffff;
      cursor: pointer;
    }
    .imageSliderWrap {
      position: relative;
      aspect-ratio: 1/0.91;
      width: 100%;
      .swipeBtn {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 50px;
        height: 50px;
        z-index: 2;
        cursor: pointer;
        &.right {
          left: auto;
          right: 0;
        }
      }
      .reviewImage {
        width: 100%;
        aspect-ratio: 1/0.91;
        object-fit: cover;
      }
    }
    .reviewInfoWrap {
      background-color: white;
      padding: 23px 0 60px;
      max-height: 20vh;
      overflow: scroll;
      .userInfoWrap {
        display: flex;
        gap: 4px;
        .userId {
          font-weight: 700;
          font-size: 16px;
          line-height: 19px;
          color: #131c31;
          padding: 4px 10px;
          display: inline-block;
        }
        .userInfo {
          font-weight: 700;
          font-size: 16px;
          line-height: 19px;
          color: #abb1bb;
          padding: 4px 10px;
          display: inline-block;
        }
      }
      .starWrap {
        display: flex;
        padding: 4px 10px;
        img {
          width: 22px;
          height: 22px;
        }
      }
      .content {
        padding: 20px 13px;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #131c31;
      }
    }
  }
}

:global(.pc) {
  .reviewDetailModal {
    width: 850px;
    left: 50%;
    transform: translateX(-50%);
  }
  .reviewDetailContent {
    width: 596px;
    left: 50%;
    transform: translate(-50%, -50%);
    .swipeBtn {
      width: 69px !important;
      height: 69px !important;
    }
  }
}
