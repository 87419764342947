.contentWrap {
  &.isDesktop {
    .downWrap {
      .leftWrap {
      }
    }
    .kcalWrap {
      padding: 0;
      & > div {
        padding: 0;
        background: transparent;
        font-size: 14px;
        line-height: 20px;
      }
    }
    .listWrap {
      flex-direction: row;
      flex-wrap: wrap;
      .listFrame {
        width: calc(100% / 2 - 8px);
        .listContentWrap {
          .contentFrame {
          }
        }
      }
      .basketWrap {
        width: calc(100% / 2 - 8px);
      }
    }
  }
  .downWrap {
    padding: 20px 33px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .leftWrap {
      display: flex;
      flex-direction: column;
      span:first-child {
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
      }
      span:nth-child(2) {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }
    }
    .rightWrap {
      cursor: pointer;
      display: flex;
      gap: 4px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      img {
        width: 24px;
        height: 24px;
      }
      span {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #3259e6;
      }
    }
  }
  .kcalWrap {
    padding: 0 21px;
    & > div {
      padding: 12px 0;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #666;
      text-align: center;
      background: #f9f9f9;
      border-radius: 19.5px;
    }
  }
  .listWrap {
    padding: 21px 21px 16px;
    display: flex;
    gap: 16px;
    flex-direction: column;
    .listFrame {
      .dateWrap {
        padding: 20px 32px;
        display: flex;
        gap: 10px;
        align-items: center;
        background: #3259e6;
        border-radius: 10px 10px 0 0;
        box-sizing: border-box;
        span:first-child {
          font-weight: 600;
          font-size: 20px;
          line-height: 28px;
          color: #fff;
        }
        span:nth-child(2) {
          font-weight: 400;
          font-size: 14px;
          line-height: 28px;
          color: #fff;
        }
      }
      .listContentWrap {
        padding: 16px;
        &:last-child {
          padding: 0 16px 16px;
          border-radius: 0 0 10px 10px;
        }
        display: flex;
        gap: 8px;
        flex-direction: column;
        background: #f9f9f9;
        .contentFrame {
          padding: 16px 13px 12px;
          background: #fff;
          border-radius: 10px;
          .title {
            padding: 0 4px 16px;
            display: flex;
            gap: 10px;
            align-items: center;
            & > span:first-child {
              font-weight: 600;
              font-size: 17px;
              line-height: 20px;
              color: #3259e6;
              letter-spacing: 3px;
            }
            & > div {
              display: flex;
              gap: 4px;
              align-items: center;
              span:first-child {
                font-weight: 400;
                font-size: 10px;
                line-height: normal;
                color: #666;
              }
              span:last-child {
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                color: #3259e6;
              }
            }
          }
          .foodList {
            padding: 0 4px;
            .foodFrame {
              padding: 8px 0;
              display: flex;
              justify-content: space-between;
              border-top: 1px solid #d9d9d9;
              .foodTitle {
                display: flex;
                gap: 4px;
                flex-direction: column;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
              }
              .foodCount {
                display: flex;
                gap: 4px;
                flex-direction: column;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                text-align: right;
              }
            }
          }
          .kcalList {
            padding: 12px 0;
            display: flex;
            align-items: center;
            background: #f9f9f9;
            & > div {
              position: relative;
              display: flex;
              gap: 3px;
              flex-direction: column;
              text-align: center;
              width: calc(100% / 3);
              span:first-child {
                font-weight: 400;
                font-size: 10px;
                line-height: normal;
                color: #666;
              }
              span:last-child {
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
              }
              &::after {
                content: '';
                position: absolute;
                right: 0;
                width: 1px;
                height: 33px;
                background: #d9d9d9;
              }
              &:last-child::after {
                display: none;
              }
            }
          }
        }
      }
    }
    .basketWrap {
      .basketFrame {
        padding: 20px;
        background: #edf8ff;
        border: 1px solid #3259e6;
        border-radius: 10px;
        height: 514px;
        overflow: scroll;
        box-sizing: border-box;
        .basketTitle {
          span:first-child {
            font-weight: 300;
            font-size: 20px;
            line-height: 28px;
            color: #3259e6;
          }
          span:last-child {
            font-weight: 600;
            font-size: 20px;
            line-height: 28px;
            color: #3259e6;
          }
        }
        .basketListWrap {
          padding: 20px 0;
          .basketListFrame {
            padding: 10px 0;
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #dae8fc;
            // .checkWrap {
            //   display: flex;
            //   gap: 4px;
            //   & > span {
            //     font-weight: 400;
            //     font-size: 14px;
            //     line-height: 20px;
            //   }
            // }
            & > span {
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
            }
          }
        }
      }
    }
  }
}
