.container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 999999999;
  display: flex;
  justify-content: center;
  align-items: center;
  .modal {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 10px;
  }
}
.closeBtn {
  width: 24px;
  height: 24px;
  background: none;
  border: none;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
  }
}
.contentBox {
  max-width: 334px;
  max-height: 433px;
  background-color: #fff8d1;
  // background-image: url('../../assets/adLink/background.png');
  // background-position: center;
  // background-size: cover;
  filter: drop-shadow(0px 2px 17px rgba(0, 0, 0, 0.4));
  transform: traslate3d(0, 0, 0);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 49px 7px 28px;
  box-sizing: border-box;
  h1 {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
    padding: 10px;
  }
  img {
    max-width: 100%;
  }
  .priceWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5px;
    .price {
      padding: 2px 0;
      span {
        font-size: 15px;
        font-weight: 500;
        line-height: 18px;
        color: rgba(68, 47, 44, 0.5);
        &:last-child {
          text-decoration: line-through;
        }
      }
    }
    .discountedPrice {
      padding: 2px 0;
      span {
        font-family: Pretendard;
        font-size: 24px;
        font-weight: 700;
        line-height: 29px;
        color: rgba(255, 62, 62, 1);
      }
    }
  }
  h2 {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: rgba(68, 47, 44, 1);
    padding: 5px 0;
    margin-bottom: 30px;
  }
  button {
    border: 1px solid rgba(32, 32, 32, 0.8);
    background: none;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    padding: 5px 39px;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: rgba(33, 33, 33, 1);
    cursor: pointer;
  }
}

.newContentBox {
  max-width: 280px;
  max-height: 421px;
  position: relative;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
  }
  .submitBtn {
    position: absolute;
    bottom: 4.9%;
    left: 5%;
    right: 5%;
    aspect-ratio: 250/40;
    cursor: pointer;
    // background-color: red;
  }
}
