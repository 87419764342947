.container {
  max-width: 400px;
  padding: 50px 20px 120px;
  margin: 0 auto;
  .form {
    display: flex;
    input,
    button {
      box-sizing: border-box;
      height: 38px;
    }
    input {
      flex-grow: 1;
      background: #fff;
      border: 1px solid #e5e5e5;
      border-radius: 5px 0 0 5px;
      height: 38px;
      padding-left: 10px;
    }
    button {
      width: 69px;
      align-items: center;
      background: #131c31;
      border-radius: 0 5px 5px 0;
      color: #e5e5e5;
      cursor: pointer;
      font-size: 15px;
      font-weight: 500;
      line-height: 18px;
      outline: none;
      border: none;
    }
  }
  & > p {
    color: rgba(19, 28, 49, 0.5);
    font-size: 14px;
    font-weight: 500;
    line-height: 13px;
    padding: 7px 0;
  }
  & > .newUrl {
    background-color: red;
    color: white;
  }
  ul {
    margin-top: 100px;
    li {
      margin-bottom: 20px;
      span {
        font-size: 28px;
        font-weight: bold;
        color: red;
      }
      img {
        width: 100%;
      }
    }
  }
}
