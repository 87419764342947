.categorySelectWrap {
  padding: 88px 0;
  & > h3 {
    text-align: center;
    font-weight: 700;
    font-size: 36px;
    line-height: 43px;
    letter-spacing: -0.5px;
    color: #141736;
    margin-bottom: 10px;
  }
  .selectProgramListWrap {
    &.isMobile {
      margin: 42px 21px;
      & > li {
        padding: 20px 5px 20px 18px;
        .programLeft {
          .programInfo {
            & > p {
              font-size: 20px;
            }
            & > span {
              font-size: 12px;
            }
          }
        }
        img {
          width: 25px;
          height: 25px;
        }
      }
    }
    margin: 42px 0;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    & > li {
      border: 1px solid #efefef;
      border-radius: 10px;
      padding: 30px 21px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .programLeft {
        display: flex;
        align-items: center;
        gap: 10px;
        .programInfo {
          & > p {
            font-weight: 600;
            font-size: 22px;
            letter-spacing: -0.5px;
            color: #232323;
          }
          & > span {
            color: #838a9b;
          }
        }
      }
      img {
        width: 35px;
        height: 35px;
      }
    }
  }
}
