.heighter {
  height: 131px;
  transition: 0.2s linear;
  &.smaller {
    height: 49px;
  }
}
:global .pc {
  .container {
    min-width: 850px;
  }
}
.container {
  position: fixed;
  top: 52px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  min-width: 850px;
  transition: 0.2s linear;
  z-index: 100;
  background-color: white;

  & > div {
    max-width: 850px;
    margin: 0 auto;
  }
  .header {
    padding: 0 20px 0 40px;
    display: flex;
    align-items: center;
    background-color: white;
    height: 56px;
    box-sizing: border-box;
    .logo {
      width: 138px;
      height: 34px;
      object-fit: cover;
      cursor: pointer;
    }
    .searchForm {
      margin-left: 54px;
      position: relative;
      cursor: pointer;
      input {
        width: 280px;
        height: 40px;
        background: #f8f9fd;
        border-radius: 16px;
        box-sizing: border-box;
        border: none;
        outline: none;
        padding-left: 19px;
        &::placeholder {
          font-weight: 500;
          font-size: 15px;
          line-height: 18px;
          letter-spacing: 0.004em;
          color: #abb1bb;
        }
      }
      img {
        position: absolute;
        top: 50%;
        right: 17px;
        transform: translateY(-50%);
        width: 22px;
        height: 22px;
      }
    }
    .btns {
      margin-left: auto;
      display: flex;
      gap: 15px;
      li {
        position: relative;
        &:last-child::after {
          display: none;
        }
        &::after {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          right: -8px;
          transform: translateY(-50%);
          width: 1px;
          height: 12px;
          background-color: #dcdee1;
        }
        a {
          display: inline-block;
          padding: 4px;
          font-weight: 400;
          font-size: 15px;
          line-height: 18px;
          color: #6e6d79;
        }
      }
    }
    .globalLanguageBtn {
      width: 24px;
      height: 100%;
      margin-left: 10px;
      cursor: pointer;
      position: relative;
      display: flex;
      align-items: center;
      & > img {
        width: 24px;
        height: 24px;
      }
      ul {
        position: absolute;
        bottom: 0;
        right: 0;
        transform: translateY(100%);
        z-index: 1;
        box-shadow: 0px 17px 20px 0px #0000001a;
        padding: 10px 13px;
        width: 106px;
        background-color: white;
        cursor: initial;
        li {
          padding: 10px 0;
          text-align: center;
          cursor: pointer;
          span {
            font-size: 15px;
            font-weight: 400;
            line-height: 18px;
            color: black;
          }
        }
      }
    }
  }
}
.navigation {
  height: 74px;
  & > ul {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
    border-bottom: 1px solid #efefef;
    position: relative;
    background-color: white;
    & > li {
      width: 86px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 700;
      font-size: 19px;
      line-height: 23px;
      color: #131c31;
      cursor: pointer;
      z-index: 1;
      ul {
        display: none;
        width: 100%;
        height: 52px;
        position: absolute;
        bottom: 0;
        left: 50%;
        justify-content: center;
        align-items: center;
        gap: 24px;
        transform: translate(-50%, 100%);
        border-top: 1px solid #efefef;
        border-bottom: 1px solid #efefef;
        background-color: white;
        li {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 86px;
          height: 39px;
          flex-shrink: 0;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #131c31;
        }
      }
    }
    .subMenuWrap {
      &:hover ul {
        display: flex;
      }
    }
    .dietMenuWrap {
      width: 140px;
    }
    .movingBar {
      position: absolute;
      bottom: 0;
      width: 0px;
      height: 3px;
      background: #131c31;
      transition: all 0.2s linear;
    }
  }
}
.container.tiny {
  top: -4px;
  .navigation {
    height: 48px;
  }
}

.mContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 21px;
  height: 62px;
  box-sizing: border-box;
  position: fixed;
  width: 100%;
  max-width: 850px;
  top: 52px;
  left: 50%;
  z-index: 100;
  background-color: white;
  transform: translateX(-50%);
  &.adBanner {
    top: 0;
  }
  .logo {
    width: 80px;
    height: 20px;
  }
  .btns {
    display: flex;
    gap: 10px;
    height: 100%;
    align-items: center;
    position: relative;
    img {
      width: 24px;
      height: 24px;
      object-fit: cover;
    }
    & > ul {
      position: absolute;
      bottom: 0;
      right: 0;
      transform: translateY(100%);
      box-shadow: 0px 17px 20px 0px #0000001a;
      padding: 10px 13px;
      width: 106px;
      background-color: white;
      cursor: initial;
      li {
        text-align: center;
        padding: 10px 0;
        span {
          font-size: 15px;
          font-weight: 400;
          line-height: 18px;
        }
      }
    }
  }
}
.mHeighter {
  height: 62px;
}
