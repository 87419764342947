.container {
  padding: 20px;
  border-bottom: 10px solid #f8f9fd;
  .userInfoWrap {
    .userId {
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      color: #131c31;
      padding: 4px 10px;
    }
    .userInfo {
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      color: #abb1bb;
      padding: 4px 10px;
    }
  }

  .starWrap {
    padding: 4px 10px 14px;
    img {
      width: 22px;
      height: 22px;
    }
  }
  .content {
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    color: #131c31;
    padding: 10px;
    border-top: 1px solid #f8f9fd;
  }
  .reviewImageWrap {
    margin-top: 21px;
    display: flex;
    gap: 10px;
    img {
      width: calc(calc(100% - 20px) / 3);
      aspect-ratio: 1/1;
      object-fit: cover;
      max-width: 175px;
      cursor: pointer;
    }
  }
}
