.modalContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.5);
  &.isMobile {
  }
  .modalWrap {
    position: absolute;
    top: 50%;
    // right: 15px;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    border-radius: 32px;
    background: #fff;
    min-width: 333px;
    width: 333px;
    max-height: 178px;
    aspect-ratio: 333/178;
    .closeWrap {
      cursor: pointer;
      position: absolute;
      right: 20px;
      top: -30px;
      color: #fff;
    }
    .textWrap {
      padding: 40px 0 30px;
      display: flex;
      gap: 8px;
      flex-direction: column;
      text-align: center;
      span:first-child {
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        color: #131c31;
      }
      span:last-child {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #666;
      }
    }
    .borderWrap {
      content: '';
      position: relative;
      left: 50%;
      transform: translate(-50%);
      width: 40px;
      height: 1px;
      background: #d9d9d9;
    }
    .alertWrap {
      padding: 20px 0 0 0;
      text-align: center;
      span {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #666;
      }
      img {
        width: 20px;
        height: 20px;
        aspect-ratio: 1/1;
      }
    }
    .btnWrap {
      cursor: pointer;
      position: absolute;
      bottom: 0;
      display: flex;
      width: 100%;
      height: 52px;
      font-weight: 600;
      font-size: 17px;
      line-height: normal;
      div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 52px;
      }
      div:first-child {
        border-radius: 0 0 0 32px;
        background: #edf8ff;
        color: #3259e6;
      }
      div:last-child {
        border-radius: 0 0 32px 0;
        background: #3259e6;
        color: #fff;
      }
    }
  }
}

:global(.pc) {
  .modalContainer {
    width: 850px;
    left: 50%;
    transform: translateX(-50%);
  }
  .modalWrap {
    width: 279px;
    height: 340px;
    left: 50%;
    transform: translate(-50%, -50%);
    .challengeSubmitInfoWrap {
      margin-left: 25px;
      div {
        margin-right: 20px;
      }
    }
  }
}
