.container {
  .contentWrap {
    background: #f9f9f9;
    .topWrap {
      padding: 20px 33px;
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      color: #131c31;
      background: #fff;
    }
    .listWrap {
      padding: 21px 21px 16px;
      .deliveryWrap {
        padding: 16px;
        display: flex;
        gap: 16px;
        flex-direction: column;
        background: #fff;
        border-radius: 5px;
        .deliveryFrame {
          display: flex;
          gap: 8px;
          flex-direction: column;
          &.warning {
            .titleWrap {
              span {
                color: #ff5656;
              }
            }
            .inputWrap {
              input {
                border: 1px solid #ff5656;
              }
            }
            .addressInputWrap {
              .address1Input {
                border: 1px solid #ff5656;
              }
            }
          }
          .titleWrap {
            span {
              font-weight: 500;
              font-size: 14px;
              line-height: 20px;
              color: #666;
            }
            span:first-child {
              color: #ff5656;
            }
          }
          .inputWrap {
            position: relative;
            display: flex;
            height: 48px;
            input {
              padding: 0 10px;
              box-sizing: border-box;
              width: 100%;
              border-radius: 5px;
              border: 1px solid #d9d9d9;
            }
            input:disabled {
              background: #fff;
            }
            .payChangeBtn {
              cursor: pointer;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 106px;
              height: 48px;
              background: #3259e6;
              font-weight: 500;
              font-size: 14px;
              line-height: 20px;
              color: #fff;
              border-radius: 0 5px 5px 0;
            }
            img {
              position: absolute;
              width: 27px;
              height: 27px;
              top: 50%;
              left: 10px;
              transform: translateY(-50%);
            }
          }
          .changeInputWrap {
            input {
              padding: 0 10px 0 40px;
              box-sizing: border-box;
              border-radius: 5px 0 0 5px;
              width: calc(100% - 106px);
            }
          }
          .addressInputWrap {
            display: flex;
            flex-direction: column;
            gap: 8px;
            input {
              padding: 0 10px;
              box-sizing: border-box;
              height: 48px;
              border-radius: 5px;
              border: 1px solid #d9d9d9;
              box-sizing: border-box;
            }
            .searchWrap {
              display: flex;
              &.warning {
                & > input {
                  border: 1px solid #ff5656;
                }
              }
              & > input {
                width: calc(100% - 106px);
                border-radius: 5px 0 0 5px;
              }
              .searchBtn {
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 106px;
                height: 48px;
                background: #131c31;
                border-radius: 0 5px 5px 0;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: #fff;
              }
            }
          }
        }
      }
    }
    .noticeWrap {
      padding: 0 33px 80px;
      display: flex;
      gap: 4px;
      align-items: center;
      img {
        width: 16px;
        height: 16px;
      }
      span {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #999;
      }
    }
    .changeBtnWrap {
      cursor: pointer;
      padding: 10px 21px;
      background: #fff;
      .changeBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 52px;
        border-radius: 8px;
        background: #3259e6;
        font-weight: 600;
        font-size: 17px;
        line-height: normal;
        color: #fff;
      }
    }
  }
  .addressModalWrap {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 100;
    .addressModal {
      position: absolute;
      top: 50%;
      left: 21px;
      right: 21px;
      transform: translateY(-50%);
      .modalHeader {
        background-color: #131c31;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 11px;
        img {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}
