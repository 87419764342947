.container {
  // background: url('../../assets/loginBanner.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
  min-height: 100vh;
  padding: 60px 7px 146px;
  box-sizing: border-box;
  &.isMobile {
    padding-top: 57px;
  }
}
.backBtn {
  margin-bottom: 87px;
  img {
    width: 20px;
    height: 16px;
  }
}
.contentWrap {
  max-width: 360px;
  margin: 0 auto;
  .title {
    font-weight: 600;
    font-size: 32px;
    line-height: 38px;
    letter-spacing: -0.5px;
    color: #000000;
    text-align: center;
    padding: 10px 0;
  }
}
.kakaoLoginBtn {
  background: #fee500;
  border-radius: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 55px;
  cursor: pointer;
  margin: 0 auto;
  margin-top: 44px;
  width: 100%;
  max-width: 333px;
  img {
    position: absolute;
    width: 48px;
    height: 48px;
    top: 50%;
    left: 14px;
    transform: translateY(-50%);
  }
  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #1c1c1e;
  }
}
.or {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 9px;
  padding: 37px 0 28px;
  width: 100%;
  max-width: 334px;
  margin: 0 auto;
  div {
    flex-grow: 1;
    height: 1px;
    background: rgba(19, 28, 49, 0.3);
  }
  span {
    font-weight: 300;
    font-size: 15px;
    line-height: 18px;
    color: rgba(19, 28, 49, 0.3);
  }
}
.joinForm {
  display: flex;
  flex-direction: column;
  // background: rgba(255, 255, 255, 0.7);
  // box-shadow: 2px 5px 15px rgba(3, 133, 0, 0.15);
  // border-radius: 24px;
  padding: 0px 13px;
}
.id {
  & > span {
    display: inline-block;
    padding: 11px 0;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #131c31;
    &::after {
      content: '*';
      display: inline-block;
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      color: #ff5656;
      margin-left: 1px;
    }
  }
  .idInputWrap,
  .emailInputWrap {
    display: flex;
    align-items: center;
    input {
      flex-grow: 1;
      background: #ffffff;
      border: 1px solid #e5e5e5;
      border-right: none;
      border-radius: 5px 0 0 5px;
      height: 48px;
      box-sizing: border-box;
      padding-left: 10px;
      color: #141736;
      font-weight: 400;
      font-size: 15px;
      min-width: 0;
      line-height: 18px;
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      color: #000000;
      &::placeholder {
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        color: rgba(19, 28, 49, 0.2);
      }
    }
    .idCheck,
    .emailCheck {
      height: 48px;
      background: #131c31;
      border-radius: 0px 5px 5px 0px;
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #e5e5e5;
      width: 69px;
      cursor: pointer;
      flex-shrink: 0;
    }
  }
}
.pw,
.pwCheck,
.name {
  display: flex;
  flex-direction: column;
  & > span {
    display: inline-block;
    padding: 11px 0;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #131c31;
    &::after {
      content: '*';
      display: inline-block;
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      color: #ff5656;
      margin-left: 1px;
    }
  }
  input {
    height: 48px;
    box-sizing: border-box;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #000000;
    padding-left: 10px;
    &::placeholder {
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      color: rgba(19, 28, 49, 0.2);
    }
  }
}
.tel {
  & > span {
    display: inline-block;
    padding: 11px 0;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #131c31;
    &::after {
      content: '*';
      display: inline-block;
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      color: #ff5656;
      margin-left: 1px;
    }
  }
  .telInputWrap {
    display: flex;
    align-items: center;
    input {
      min-width: 0;
      background: #ffffff;
      border: 1px solid #e5e5e5;
      border-radius: 5px;
      height: 48px;
      // text-align: center;
      padding-left: 10px;
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      color: #000000;
      width: 100%;
      &::placeholder {
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        color: rgba(19, 28, 49, 0.2);
      }
    }
    span {
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      color: #131c31;
      display: inline-block;
      padding: 0 2px;
    }
  }
  & > p {
    padding: 6px 10px 8px;
    text-align: right;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #131c31;
  }
}
.email {
  & > span {
    display: inline-block;
    padding: 11px 0;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #131c31;
    &::after {
      content: '*';
      display: inline-block;
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      color: #ff5656;
      margin-left: 1px;
    }
  }
  .emailInputWrap {
    display: flex;
    & > input {
      min-width: 0;
      box-sizing: border-box;
      background: #ffffff;
      border: 1px solid #e5e5e5;
      border-radius: 5px;
      width: calc(50% - 10px);
      padding-left: 10px;
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      color: #000000;
    }
    & > span {
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      color: #131c31;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20px;
    }
    & > div {
      display: flex;
      box-sizing: border-box;
      width: calc(50% - 10px);
      & > .customSelect {
        box-sizing: border-box;
        flex-grow: 1;
        background: #ffffff;
        border: 1px solid #e5e5e5;
        border-radius: 5px;
        position: relative;
        overflow: hidden;
        input {
          min-width: 0;
          box-sizing: border-box;
          width: 100%;
          height: 46px;
          padding-left: 10px;
          border: none;
          outline: none;
        }
        select {
          width: 100%;
          border: none;
          box-sizing: border-box;
          height: 100%;
          outline: none;
          border-left: none;
          border-right: none;
          position: absolute;
          top: 0;
          left: 0;
          appearance: none;
          padding-left: 10px;
          font-weight: 600;
          font-size: 13px;
          line-height: 16px;
          color: #000000;
          // display: none;
        }
        img {
          width: 24px;
          height: 24px;
          position: absolute;
          top: 50%;
          right: 5px;
          transform: translateY(-50%);
          cursor: pointer;
        }
      }
    }
  }
  .emailCheck {
    background: #131c31;
    border-radius: 0px 5px 5px 0px;
    width: 100%;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #e5e5e5;
    flex-shrink: 0;
    cursor: pointer;
    margin-top: 12px;
  }
}
.referral {
  & > span {
    display: inline-block;
    padding: 23px 0 11px 0;
    color: #131c31;
    font-size: 15px;
    font-weight: 500;
  }
  .referralInputWrap {
    display: flex;
    align-items: center;
    input {
      min-width: 0;
      background: #ffffff;
      border: 1px solid #e5e5e5;
      border-radius: 5px;
      height: 48px;
      padding-left: 10px;
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      color: #000000;
      width: 100%;
      &::placeholder {
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        color: rgba(19, 28, 49, 0.2);
      }
    }
    span {
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      color: #131c31;
      display: inline-block;
      padding: 0 2px;
    }
  }
  margin-bottom: 12px;
  .referralJoinMsg {
    text-align: right;
    color: #131c31;
    font-size: 15px;
    font-weight: 500;
  }
}
.marketing {
  & > span {
    display: inline-block;
    padding: 11px 0;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #131c31;
    &::after {
      content: '*';
      display: inline-block;
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      color: #ff5656;
      margin-left: 1px;
    }
  }
  & > ul {
    display: flex;
    li {
      width: 33%;
      height: 44px;
      flex-grow: 1;
      display: flex;
      align-items: center;
      gap: 6px;
      padding-left: 12px;
      cursor: pointer;

      .checkbox {
        width: 19px;
        height: 19px;
        box-sizing: border-box;
        border: 1.5px solid rgba(19, 28, 49, 0.2);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        &.hit {
          background: #ff5656;
          border: 1.5px solid #ff5656;
          &::after {
            background-color: white;
          }
        }
        &::after {
          content: '';
          display: block;
          background: rgba(19, 28, 49, 0.2);
          width: 8px;
          height: 8px;
          border-radius: 50%;
        }
      }
      span {
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: #131c31;
      }
    }
  }
  p {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: #131c31;
    padding: 6px 0 8px;
  }
}
.agreement {
  margin-top: 32px;
  padding: 10px 0;
  & > h3 {
    display: flex;
    align-items: center;
    gap: 5px;
    height: 44px;
    cursor: pointer;
    width: fit-content;
    .customCheckbox {
      background: #ffffff;
      border: 1.5px solid #e5e5e5;
      border-radius: 3px;
      width: 21px;
      height: 21px;
      box-sizing: border-box;
      &.hit {
        background: #3559ed;
        border: 1.5px solid #3559ed;
        background-image: url('../../assets/checkMark.png');
        background-repeat: no-repeat;
        background-size: 12px 9px;
        background-position: center center;
      }
    }
    span {
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      color: #131c31;
    }
  }
  .agreementList {
    display: flex;
    flex-direction: column;
    li {
      display: flex;
      align-items: center;
      height: 44px;
      justify-content: space-between;
      .left {
        display: flex;
        align-items: center;
        gap: 5px;
        cursor: pointer;
        .customCheckbox {
          background: #ffffff;
          border: 1.5px solid #e5e5e5;
          border-radius: 3px;
          width: 16px;
          height: 16px;
          box-sizing: border-box;
          &.hit {
            background: #3559ed;
            border: 1.5px solid #3559ed;
            background-image: url('../../assets/checkMark.png');
            background-repeat: no-repeat;
            background-size: 9px 7px;
            background-position: center center;
          }
        }
        .title {
          font-weight: 600;
          font-size: 13px;
          line-height: 16px;
          color: #131c31;
        }
      }
      & > span:last-child {
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        text-decoration-line: underline;
        color: rgba(19, 28, 49, 0.5);
        cursor: pointer;
      }
    }
  }
}
.submitBtn {
  background: #141736;
  border-radius: 27.5px;
  height: 55px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.5px;
  color: #ffffff;
  border: none;
  box-sizing: border-box;
  cursor: pointer;
}
