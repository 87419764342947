.container {
  position: fixed;
  top: 0;
  left: 50%;
  bottom: 0;
  z-index: 1000;
  width: 100%;
  max-width: 850px;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.4);
  .contentWrap {
    position: relative;
    padding: 41px 16px 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 333px;
    max-height: 448px;
    padding-bottom: 64px;
    box-sizing: border-box;
    background-color: white;
    border-radius: 32px;
    .closeWrap {
      position: absolute;
      right: 10px;
      top: -50px;
      img {
        cursor: pointer;
        width: 40px;
        height: 40px;
      }
    }
    .titleWrap {
      padding: 0 8px;
      display: flex;
      gap: 4px;
      align-items: center;
      margin-bottom: 19px;
      span:first-child {
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
      }
      span:nth-child(2) {
        font-weight: 400;
        font-size: 17px;
        line-height: 24px;
        color: #3259e6;
      }
    }
    .listWrap {
      display: flex;
      gap: 8px;
      flex-direction: column;
      .listFrmae {
        display: flex;
        gap: 4px;
        flex-direction: column;
        background: #f6f7f9;
        border-radius: 8px;
        padding: 16px;
        span:first-child {
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
        }
        span:nth-child(2) {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
    .textWrap {
      padding: 8px;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #999;
    }
  }
}
