.contentWrap {
  position: relative;
  min-height: calc(100vh - 245px);
  &.isMobile {
    .header {
      padding: 18px 21px;
      height: 60px;
      background: #fff;
      span {
        padding: 0 0 0 36px;
      }
    }
    .registerBtnWrap {
      padding: 0 21px 21px;
      position: relative;
    }
  }
  .orderHeader {
    .naviWrap {
      padding: 18px 21px;
      height: 60px;
      box-sizing: border-box;
      img {
        width: 24px;
        height: 24px;
      }
    }
    .titleWrap {
      padding: 10px 21px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 48px;
      box-sizing: border-box;
      span {
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        color: #131c31;
      }
      div:last-child {
        width: 60px;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #666;
      }
    }
  }
  .header {
    padding: 21px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
    box-sizing: border-box;
    img {
      width: 24px;
      height: 24px;
    }
    span {
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      color: #131c31;
    }
    div:last-child {
      width: 60px;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #666;
    }
  }
  .cardListWrap {
    padding: 21px;
    .cardListFrame {
      padding: 16px;
      display: flex;
      gap: 16px;
      flex-direction: column;
      border: 1px solid #d9d9d9;
      border-radius: 5px;
      .inputWrap {
        position: relative;
        display: flex;
        gap: 8px;
        flex-direction: column;
        width: 100%;
        &.waringInputWrap {
          input {
            border: 1px solid #ff5656;
          }
        }
        input {
          padding: 0 0 0 10px;
          width: calc(100% - 10px);
          height: 48px;
          border: 1px solid #d9d9d9;
          border-radius: 5px;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
        }
        input::placeholder {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
        }
        .inputDateWrap {
          display: flex;
          gap: 4px;
          align-items: center;
          height: 48px;
          border: 1px solid #d9d9d9;
          border-radius: 5px;
          &.waringInputWrap {
            border: 1px solid #ff5656;
          }
          input {
            width: 24px;
            border: none;
          }
          input:last-child {
            padding: 0;
          }
        }
        span {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #666;
        }
        .placeLabel {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #131c31;
        }
      }
      .dateWrap {
        display: flex;
        gap: 9px;
      }
      .alertWrap {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #999;
        margin-top: -8px;
      }
    }
  }
  .registerBtnWrap {
    padding: 0 21px;
    width: 100%;
    box-sizing: border-box;
    height: 52px;
    .registerBtn {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 52px;
      border-radius: 8px;
      background: #999999;
      font-weight: 600;
      font-size: 17px;
      line-height: normal;
      color: #fff;
      &.okBtn {
        background: #3259e6;
      }
    }
  }
}
