.container {
  background: #f8f9fd;
  border-radius: 16px;
  overflow: hidden;
  cursor: pointer;
}
.reviewImageWrap {
  img {
    border-radius: 10px;
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
  }
}
.reviewInfoWrap {
  padding: 16px;
}
.reviewInfoTop {
  display: flex;
  position: relative;
  .left {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    // margin-right: 13px;
    // max-width: calc(100% - 129px);
    width: 100%;
    .userIdScoreWrap {
      display: flex;
      justify-content: space-between;
      margin-bottom: 2px;
      .userId {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #131c31;
      }
      .score {
        img {
          width: 14px;
          height: 14px;
        }
        span {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
    .userInfo {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #666;
      margin-bottom: 8px;
    }
    .reviewScore {
      display: flex;
      img {
        width: 22px;
        height: 22px;
      }
    }
  }
  .right {
    background-color: white;
    border-radius: 10px;
    filter: drop-shadow(1px 6px 6px rgba(35, 47, 76, 0.04));
    position: absolute;
    right: -5px;
    top: -76px;
    aspect-ratio: 1/1;
    width: 50px;
    &.isDesktop {
      top: -106px;
      width: 80px;
    }
    img {
      width: 100%;
      aspect-ratio: 1/1;
      object-fit: cover;
      border-radius: 10px;
    }
  }
}
.reviewInfoBottom {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #131c31;
  text-overflow: ellipsis;
  display: -webkit-box;
  height: 57px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
