.container {
  background: #f6f7f9;
  &.isPC {
    padding: 0 20px;
  }
}
.header {
  display: flex;
  height: 50px;
  align-items: center;
  gap: 3px;
  background-color: #f6f7f9;
  border-bottom: 1px solid #efefef;
  cursor: pointer;
  padding: 0 21px;
  img {
    width: 21px;
    height: 21px;
  }
  span {
    font-family: 'Inter';
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.5px;
    color: #232323;
  }
}
.pcHeader {
  padding: 32px 0;
  span {
    display: inline-block;
    padding: 10px 0 10px 12px;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #232323;
  }
}
.orderStatus {
  padding: 0 20px 20px;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
  background-color: white;
  h3 {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.5px;
    color: #232323;
    padding: 18px 0;
  }
  .statusList {
    display: flex;
    .status {
      flex-grow: 1;
      flex-basis: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 11px;
      height: 70px;
      &.hit > span {
        color: #141736;
      }
      & > span {
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        letter-spacing: -0.5px;
        color: #999999;
        white-space: nowrap;
      }
    }
    & > img {
      width: 12px;
      height: 13px;
      position: relative;
      top: 14px;
    }
  }
}

.orderDateForm {
  padding: 25px 20px 44px;
  .dateList {
    display: flex;
    gap: 10px;
    &.isMobile {
      gap: 0;
    }
    li {
      &:first-child {
        flex-basis: 90%;
      }
      &.hit {
        background-color: #131c31;
        font-weight: 700;
        color: #ffffff;
        border: none;
      }
      box-sizing: border-box;
      cursor: pointer;
      flex-basis: 100%;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: white;
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      letter-spacing: -0.5px;
      color: #999999;
      background: #ffffff;
      border: 1px solid #efefef;
      border-left: none;
      &:first-child {
        border-left: 1px solid #efefef;
      }
    }
  }
  .dateChangeWrap {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 10px;
    .startDate,
    .endDate {
      background: #ffffff;
      border: 1px solid #efefef;
      width: 151px;
      height: 41px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      :global .react-datepicker-wrapper {
        height: 100%;
        :global .react-datepicker__input-container {
          height: 100%;
          input {
            height: 100%;
            width: 100%;
            box-sizing: border-box;
            text-align: center;
            border: none;
            outline: none;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            letter-spacing: -0.5px;
            color: #999999;
          }
        }
      }
    }
    & > span {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: -0.5px;
      color: #999999;
      margin: 0 2px;
    }
    .dateSubmit {
      width: 83px;
      height: 41px;
      background: #131c31;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: -0.5px;
      color: #ffffff;
      margin-left: 10px;
      cursor: pointer;
    }
  }
  .text {
    text-align: right;
    span {
      display: inline-block;
      padding: 10px;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: -0.5px;
      color: #999999;
    }
  }
}

.orderListWrap {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 20px;
  &.isMobile {
    gap: 0;
    padding-bottom: 0;
  }
  .loadWrap {
    padding: 10px 0;
    text-align: center;
  }
}

.orderGuide {
  background-color: white;
  padding-bottom: 40px;
  .head {
    display: flex;
    align-items: center;
    padding: 0 21px;
    height: 40px;
    border-top: 1px solid #efefef;
    border-bottom: 1px solid #efefef;
    background-color: white;
    img:first-child {
      width: 22px;
      height: 22px;
    }
    span {
      margin-left: 6px;
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      letter-spacing: -0.5px;
      color: #232323;
    }
    img:last-child {
      margin-left: auto;
      width: 22px;
      height: 22px;
      &.isOpen {
        transform: rotate(180deg);
      }
    }
  }
  .content {
    background-color: #f6f7f9;
    margin: 0 20px;
    padding: 0 20px;
    transition: all 0.2s linear;
    transform-origin: top;
    max-height: 0px;
    overflow: hidden;
    &.isOpen {
      max-height: 458px;
    }
    & > div:first-child {
      display: flex;
      flex-direction: column;
      gap: 2px;
      padding: 10px 0;
      border-bottom: 1px solid #999999;
      span {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: -0.5px;
        color: #999999;
        position: relative;
        padding-left: 20px;
        &::after {
          content: '';
          display: block;
          width: 2px;
          height: 2px;
          border-radius: 50%;
          background-color: #999999;
          position: absolute;
          top: 50%;
          left: 10px;
          transform: translateY(-50%);
        }
      }
    }
    & > ul:last-child {
      display: flex;
      flex-direction: column;
      gap: 24px;
      padding: 24px 0;
      li {
        display: flex;
        flex-direction: column;
        h5 {
          font-weight: 700;
          font-size: 14px;
          line-height: 17px;
          letter-spacing: -0.5px;
          color: #999999;
          margin-bottom: 6px;
        }
        span:nth-child(2) {
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          letter-spacing: -0.5px;
          color: #999999;
        }
        span:nth-child(3) {
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          letter-spacing: -0.5px;
          color: #999999;
          margin-top: 7px;
        }
      }
    }
  }
}
