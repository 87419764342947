.container {
  position: relative;
}
.processWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  // background: url('../../assets/processBg.png');
  background-size: cover;
  padding: 38px 0 67px;
  & > h3 {
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    color: #131c31;
    padding: 10px 0;
    margin: 0;
  }
  & > p {
    padding: 4px 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #131c31;
  }
  .hashtag {
    margin-top: 17px;
    display: flex;
    gap: 17px;
    li {
      padding: 8px 18px;
      background: #f8f9fd;
      border: 1px solid #e1e6ef;
      border-radius: 100px;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: -0.05em;
      color: #141736;
    }
  }
  .processSliderWrap {
    position: relative;
    width: 100%;
    .processSlider {
      margin-top: 32px;
      .processSlide {
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: grab;
        & > img {
          width: 597px;
          height: 356px;
          object-fit: cover;
          border-radius: 16px;
        }
        .stage {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 32px;
          h4 {
            font-weight: 700;
            font-size: 24px;
            line-height: 29px;
            letter-spacing: -0.5px;
            color: #131c31;
            padding: 10px 0;
          }
          p {
            font-weight: 400;
            font-size: 15px;
            line-height: 18px;
            letter-spacing: -0.5px;
            color: #131c31;
            padding: 4px 0;
          }
        }
      }
    }
    .processSliderPagination {
      position: absolute;
      bottom: 75px;
      left: 50%;
      transform: translateX(-50%);
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      li {
        width: 12px;
        height: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        div {
          width: 4px;
          height: 4px;
          background-color: #131c31;
          border-radius: 50%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        &::after {
          transition: all 0.2s;
          scale: 0;
          content: '';
          display: block;
          width: 8px;
          height: 8px;
          border: 1px solid #131c31;
          box-sizing: border-box;
          border-radius: 50%;
          transform-origin: center center;
        }
        &.hit::after {
          scale: 1;
        }
      }
    }
  }
}
.worldPlatform {
  padding: 41px 0 39px;
  background-color: #f8f9fd;
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  h3 {
    font-weight: 700;
    font-size: 24px;
    line-height: 42px;
    text-align: center;
    color: #000000;
  }
  ul {
    display: flex;
    justify-content: center;
    gap: 19px;
    padding: 34px 0 39px;
    li {
      width: 135px;
      height: 135px;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  p {
    font-weight: 400;
    font-size: 20px;
    line-height: 42px;
    text-align: center;
    letter-spacing: -0.005em;
    color: #000000;
  }
}
.prescriptionProductWrap {
  padding-top: 72px;
  & > h3 {
    display: flex;
    align-items: center;
    padding: 10px 21px;
    width: fit-content;
    cursor: pointer;
    span {
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      color: #131c31;
    }
    img {
      width: 24px;
      height: 24px;
    }
  }
  & > span {
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.004em;
    color: #131c31;
    display: inline-block;
    padding-bottom: 10px;
    padding-left: 21px;
  }
  .prescriptionProductSlider {
    padding-left: 21px;
    .prescriptionProductSlide {
      cursor: pointer;
      .thumbnailWrap {
        width: 100%;
        aspect-ratio: 1/1.2;
        border-radius: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        & > img {
          width: 100%;
          aspect-ratio: 1/1;
          object-fit: cover;
          border-radius: 16px;
        }
      }
      .productInfoWrap {
        h3 {
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
          color: #131c31;
          padding: 10px 0;
        }
        .hashtagList {
          li {
            font-weight: 400;
            font-size: 15px;
            line-height: 18px;
            letter-spacing: -0.004em;
            color: rgba(19, 28, 49, 0.5);
          }
        }
      }
    }
  }
}
.challengeWrap {
  position: relative;
  margin: 72px 0 64px;
  margin-left: calc(-50vw + 50%);
  width: 100vw;
  @media screen and (max-width: 850px) {
    margin-left: 0;
    width: 100%;
  }
  .sectionBanner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 400px;
    background-image: url('../../assets/home/challengeBanner.jpg');
    background-size: cover;
    background-position: center;
  }
  .sectionContent {
    padding: 64px 0 0;
    position: relative;
    z-index: 1;
    max-width: 850px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    & > h2 {
      color: #fff;
      font-size: 36px;
      font-weight: 700;
      padding: 10px 0;
    }
    & > p {
      padding-bottom: 26px;
      color: #fff;
      font-size: 20px;
      font-weight: 400;
      letter-spacing: -0.08px;
    }
    :global(.swiper) {
      width: 100%;
    }
    & > button {
      margin-top: 64px;
      padding: 16px 71px;
      border-radius: 45.5px;
      border: 1px solid #e5e5e5;
      color: rgba(19, 28, 49, 0.5);
      font-size: 15px;
      font-weight: 500;
      background-color: white;
      cursor: pointer;
    }
  }
}

.normalProductWrap {
  // padding: 32px 21px;
  & > h3 {
    display: flex;
    gap: 3px;
    align-items: center;
    width: fit-content;
    margin-bottom: 40px;
    cursor: pointer;
    span {
      font-weight: 600;
      font-size: 28px;
      line-height: 36px;
      color: #131c31;
    }
    img {
      width: 36px;
      height: 36px;
    }
  }
  & > span {
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.004em;
    color: #131c31;
    display: inline-block;
    padding-bottom: 10px;
  }

  .productSlideWrap {
    cursor: pointer;
  }

  .productThumbnailWrap {
    img {
      background: #fbfbfb;
      border-radius: 10px;
      width: 333px;
      aspect-ratio: 1/1;
      object-fit: cover;
    }
  }
  .productInfoWrap {
    padding: 16px;
    .productInfoLeft {
      p {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
      }
      .productInfoPrice {
        display: flex;
        gap: 4px;
        align-items: center;
        span:first-child {
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
        }
        span:nth-child(2) {
          font-weight: 600;
          font-size: 10px;
          color: #999;
          text-decoration: line-through;
        }
      }
      .productInfoScore {
        display: flex;
        gap: 3px;
        align-items: center;
        img {
          width: 14px;
          height: 14px;
        }
        span {
          font-size: 12px;
        }
      }
    }
  }
}
.allProductList {
  padding: 0 21px 64px;
  & > h3 {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #131c31;
    padding: 10px 0;
  }
  & > span {
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.004em;
    color: #131c31;
    display: inline-block;
    padding-bottom: 10px;
  }
  .productListWrap {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    column-gap: 37px;
    li {
      width: calc(50% - 19px);
      padding-bottom: 20px;
      cursor: pointer;
      .productThumbnailWrap {
        width: 100%;
        border: 1px solid #e5e5e5;
        border-radius: 5px;
        overflow: hidden;
        aspect-ratio: 1/1.2;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 100%;
          object-fit: cover;
          aspect-ratio: 1/1;
        }
      }
      .productInfoWrap {
        display: flex;
        flex-direction: column;
        & > h3 {
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
          letter-spacing: -0.004em;
          color: #131c31;
          padding: 10px 0;
        }
        .discountRate {
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
          letter-spacing: -0.05em;
          color: #ff5656;
          display: inline-block;
          padding: 0.5px 0;
        }
        .price {
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
          letter-spacing: -0.05em;
          color: #131c31;
          display: inline-block;
          padding: 1px 0;
        }
        .reviewScore {
          display: flex;
          align-items: center;
          gap: 3px;
          height: 20px;
          img {
            width: 14px;
            height: 14px;
          }
          span {
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            letter-spacing: -0.05em;
            color: #131c31;
          }
        }
      }
    }
  }
  button {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: rgba(19, 28, 49, 0.5);
    margin: 0 auto;
    border: 1px solid #e5e5e5;
    background-color: white;
    border-radius: 45.5px;
    width: 157px;
    height: 50px;
    display: block;
    margin-top: 64px;
    cursor: pointer;
  }
}
.dietBannerWrap {
  margin: 160px 0;
  padding: 0 77px;
  position: relative;
  background: #edf8ff;
  border-radius: 10px;
  height: 240px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  p:nth-child(1),
  p:nth-child(2) {
    font-size: 28px;
    line-height: 36px;
    font-weight: 700;
    color: #3259e6;
  }
  p:first-child {
    font-weight: normal;
  }
  p:nth-child(3) {
    font-size: 14px;
    line-height: 20px;
    color: var(--theme-font-color);
    margin-top: 12px;
  }
  img {
    position: absolute;
    width: 300px;
    height: 180px;
    right: 33px;
    top: 20px;
  }
}
.dietProgramWrap {
  padding: 0 21px 60px;
  .titleWrap {
    font-weight: 600;
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 40px;
  }
  .dietSwiperWrap {
    .dietSliderWrap {
      cursor: pointer;
      .dietImgWrap {
        img {
          width: 100%;
        }
      }
      .dietContentWrap {
        padding: 16px;
        & > span:first-child {
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
        .priceWrap {
          display: flex;
          align-items: center;
          gap: 4px;
          & > span:first-child {
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
          }
          & > span:nth-child(2) {
            font-weight: 600;
            font-size: 10px;
            line-height: normal;
            color: #999;
            text-decoration: line-through;
          }
        }
        .starWrap {
          display: flex;
          gap: 3px;
          img {
            width: 14px;
            height: 14px;
          }
          span {
            font-weight: 400;
            font-size: 12px;
            line-height: normal;
          }
        }
      }
    }
  }
}
.faqWrap {
  // padding: 32px 21px 64px;
  margin-bottom: 160px;
  & > h3 {
    display: flex;
    gap: 3px;
    align-items: center;
    width: fit-content;
    margin-bottom: 40px;
    cursor: pointer;
    span {
      font-weight: 600;
      font-size: 28px;
      line-height: 36px;
      color: #131c31;
    }
    img {
      width: 36px;
      height: 36px;
    }
  }
  & > span {
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.004em;
    color: #131c31;
    display: inline-block;
    padding-bottom: 10px;
  }
  & > ul {
    display: flex;
    flex-direction: column;
  }
}
.globalLegitScriptWrap {
  display: flex;
  gap: 20px;
  margin-bottom: 160px;
  img {
    width: 415px;
    height: 182px;
    object-fit: 415/182;
  }
  .legitWrap {
    position: relative;
    .legitLogo {
      position: absolute;
      right: 46px;
      bottom: 30px;
      width: 65px;
      height: 65px;
      object-fit: cover;
    }
  }
}
.legitScript {
  position: relative;
  & > img {
    width: 100%;
    aspect-ratio: 1/0.327;
    object-fit: cover;
  }
  & > div {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      width: 80px;
      height: 79px;
      object-fit: cover;
    }
    p:nth-child(2) {
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: #ffffff;
      padding: 17px 0 10px;
    }
    p:last-child {
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      text-align: center;

      color: #ffffff;
    }
  }
}
.ondocInfoWrap {
  margin-bottom: 160px;
  & > h3 {
    font-weight: 600;
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 40px;
  }
  .ondocInfo {
    display: flex;
    flex-wrap: wrap;
    row-gap: 20px;
    column-gap: 20px;
    justify-content: space-between;
    & > div:first-child {
      width: 100%;
      aspect-ratio: 850/240;
      background: #fbfbfb;
      border-radius: 10px;
      position: relative;
      & > div {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 40px;
        p:first-child {
          font-weight: 500;
          font-size: 17px;
          line-height: 24px;
          color: #3259e6;
          margin-bottom: 8px;
        }
        h3 {
          font-weight: 600;
          font-size: 20px;
          line-height: 28px;
          color: #131c31;
          margin-bottom: 12px;
        }
        p:last-child {
          font-size: 14px;
          line-height: 20px;
          color: #666;
        }
      }
      img {
        position: absolute;
        top: 35px;
        right: 17px;
        width: 168px;
        aspect-ratio: 168/120;
        object-fit: cover;
      }
    }
    & > div:nth-child(2) {
      position: relative;
      width: calc(50% - 10px);
      background: #fbfbfb;
      border-radius: 10px;
      padding: 40px 0 0 40px;
      aspect-ratio: 415/240;
      box-sizing: border-box;
      & > div {
        p:first-child {
          font-weight: 500;
          font-size: 17px;
          line-height: 24px;
          color: #3259e6;
          margin-bottom: 8px;
        }
        h3 {
          font-weight: 600;
          font-size: 20px;
          line-height: 28px;
          color: #131c31;
          margin-bottom: 12px;
        }
        p:last-child {
          font-size: 14px;
          line-height: 20px;
          color: #666;
        }
      }
      & > img {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 140px;
        aspect-ratio: 140/100;
        object-fit: cover;
      }
    }
    & > div:nth-child(3) {
      position: relative;
      width: calc(50% - 10px);
      aspect-ratio: 415/240;
      padding: 40px 0 0 40px;
      box-sizing: border-box;
      background: #fbfbfb;
      border-radius: 10px;
      & > div {
        p:first-child {
          font-weight: 500;
          font-size: 17px;
          line-height: 24px;
          color: #3259e6;
          margin-bottom: 8px;
        }
        h3 {
          font-weight: 600;
          font-size: 20px;
          line-height: 28px;
          color: #131c31;
          margin-bottom: 12px;
        }
        p:last-child {
          font-size: 14px;
          line-height: 20px;
          color: #666;
        }
      }
      & > img {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 140px;
        aspect-ratio: 140/100;
        object-fit: cover;
      }
    }
  }
}

.doctorInfoWrap {
  // background: linear-gradient(0deg, #f8f9fd, #f8f9fd), rgba(41, 105, 255, 0.3);
  // background-size: cover;
  display: flex;
  flex-direction: column;
  margin-bottom: 160px;
  & > h3 {
    font-weight: 600;
    font-size: 28px;
    line-height: 36px;
    color: #131c31;
    margin-bottom: 40px;
  }
  .doctorSliderWrap {
    position: relative;
    width: 100%;
    .doctorSlider {
      width: 100%;
      box-sizing: border-box;
      .doctor {
        width: 100%;
        .doctorImageWrap {
          width: 100%;
          text-align: center;
          border-radius: 16px;
          overflow: hidden;
          img {
            width: 270px;
            height: 220px;
            object-fit: cover;
            border-radius: 10px;
            &.bigImage {
              object-position: top;
            }
          }
        }
        .doctorInfo {
          display: flex;
          flex-direction: column;
          background: #fbfbfb;
          height: 265px;
          border-radius: 0 0 10px 10px;
          h4 {
            padding: 32px 32px 0 32px;
            font-family: 'Manrope';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            color: #131c31;
          }
          & > span {
            display: inline-block;
            padding: 0 32px;
            font-weight: 400;
            font-size: 15px;
            line-height: 20px;
            color: #666;
            margin-bottom: 12px;
          }
          ul {
            padding: 0 32px 34px 32px;
            display: flex;
            flex-direction: column;
            li {
              font-weight: 400;
              font-size: 15px;
              line-height: 20px;
              color: #131c31;
            }
          }
        }
      }
    }
    .doctorSliderPagination {
      position: absolute;
      bottom: 198px;
      left: 50%;
      transform: translateX(-50%);
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      li {
        width: 12px;
        height: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        div {
          width: 4px;
          height: 4px;
          background-color: #131c31;
          border-radius: 50%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        &::after {
          transition: all 0.2s;
          scale: 0;
          content: '';
          display: block;
          width: 8px;
          height: 8px;
          border: 1px solid #131c31;
          box-sizing: border-box;
          border-radius: 50%;
          transform-origin: center center;
        }
        &.hit::after {
          scale: 1;
        }
      }
    }
  }
}

.ondocService {
  background: #fbfbfb;
  padding: 50px 0;
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  @media screen and (max-width: 850px) {
    width: 100%;
    margin: 0 auto;
  }
  .textWrap {
    display: flex;
    gap: 93px;
    justify-content: center;
    align-items: center;
    span {
      font-weight: 600;
      font-size: 28px;
      line-height: 36px;
      color: #131c31;
    }
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #131c31;
    }
  }
}
