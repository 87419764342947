.container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  max-width: 850px;
  background: rgba(0, 0, 0, 0.4);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  .modal {
    width: calc(100% - 42px);
    max-width: 333px;
    background-color: white;
    & > h3 {
      padding: 36px 20px 10px;
    }
    & > .content {
      border: 1px solid rgba(0, 0, 0, 0.1);
      margin: 10px 20px 0;
      padding: 13px 18.5px 11px;
      height: 291px;
      overflow: scroll;
      box-sizing: border-box;
      font-weight: 300;
      font-size: 15px;
      line-height: 18px;
      color: rgba(0, 0, 0, 0.5);
    }
    .submit {
      margin-top: 40px;
      background: #131c31;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      color: #ffffff;
      cursor: pointer;
    }
  }
}
.twoAgreements {
  h3 {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #131c31;
    padding: 12px 20px 10px;
  }
  .content {
    padding: 11px 16.5px;
    font-weight: 300;
    font-size: 15px;
    line-height: 18px;
    margin: 0 22px;
    color: rgba(0, 0, 0, 0.5);
    height: 112px;
    overflow: scroll;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
  }
}
