.bannerSliderWrap {
  padding: 32px 21px 0 21px;
  position: relative;
  .bannerSlider {
    border-radius: 16px;
    overflow: hidden;
    img {
      width: 100%;
      // aspect-ratio: 1/1.2;
      // object-fit: cover;
      aspect-ratio: 339/400;
    }
  }
  .paginationWrap {
    position: absolute;
    bottom: 8px;
    left: 42px;
    display: flex;
    gap: 4px;
    z-index: 1;
    li {
      width: 8px;
      height: 8px;
      background-color: white;
      border-radius: 4px;
      transition: width 0.2s linear;
      cursor: pointer;
      &.hit {
        width: 24px;
      }
    }
  }
}

.processInfoWrap {
  background-size: cover;
  padding: 20px 0px 41px;
  & > h3 {
    padding: 10px 21px;
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    color: #131c31;
  }
  & > p {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #131c31;
    padding: 4px 21px;
  }
  & > ul {
    display: flex;
    column-gap: 13px;
    row-gap: 12px;
    flex-wrap: wrap;
    margin-top: 19px;
    padding: 0 21px;
    li {
      background: #f8f9fd;
      border: 1px solid #e1e6ef;
      border-radius: 100px;
      padding: 8px 18px;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: -0.05em;
      color: #141736;
    }
  }
  .processSliderWrap {
    position: relative;
    .processSlider {
      margin-top: 32px;
      padding: 0 21px;
      .processSlide {
        & > img {
          width: 100%;
          aspect-ratio: 1/0.6;
          border-radius: 16px;
        }
        .stage {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 32px;
          h4 {
            font-weight: 700;
            font-size: 24px;
            line-height: 29px;
            letter-spacing: -0.5px;
            color: #131c31;
            padding: 10px 0;
          }
          p {
            font-weight: 400;
            font-size: 15px;
            line-height: 18px;
            letter-spacing: -0.5px;
            color: #131c31;
            padding: 4px 0;
          }
        }
      }
    }
    .processSliderPagination {
      position: absolute;
      bottom: 75px;
      left: 50%;
      transform: translateX(-50%);
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      li {
        width: 12px;
        height: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        div {
          width: 4px;
          height: 4px;
          background-color: #131c31;
          border-radius: 50%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        &::after {
          transition: all 0.2s;
          scale: 0;
          content: '';
          display: block;
          width: 8px;
          height: 8px;
          border: 1px solid #131c31;
          box-sizing: border-box;
          border-radius: 50%;
          transform-origin: center center;
        }
        &.hit::after {
          scale: 1;
        }
      }
    }
  }
}
.worldPlatform {
  padding: 32px 21px;
  background-color: #f8f9fd;
  h3 {
    font-weight: 700;
    font-size: 24px;
    line-height: 35px;
    text-align: center;
    color: #000000;
    max-width: 314px;
    margin: 9px auto 0;
    word-break: keep-all;
  }
  ul {
    display: flex;
    gap: 5px;
    justify-content: center;
    padding: 32px 0 41px;
    li {
      width: 25%;
      img {
        width: 100%;
      }
    }
  }
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: -0.005em;
    color: #000000;
  }
}
.prescriptionProductWrap {
  padding-top: 32px;
  & > h3 {
    display: flex;
    align-items: center;
    padding: 10px 21px;
    width: fit-content;
    cursor: pointer;

    span {
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      color: #131c31;
    }
    img {
      width: 24px;
      height: 24px;
    }
  }
  & > span {
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.004em;
    color: #131c31;
    display: inline-block;
    padding-bottom: 10px;
    padding-left: 21px;
  }
  .prescriptionProductSlider {
    padding-left: 21px;
    .prescriptionProductSlide {
      & > img {
        width: 100%;
        aspect-ratio: 1/1.2;
        object-fit: cover;
        border-radius: 16px;
      }
      .productInfoWrap {
        h3 {
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
          color: #131c31;
          padding: 10px 0;
        }
        .hashtagList {
          li {
            font-weight: 400;
            font-size: 15px;
            line-height: 18px;
            letter-spacing: -0.004em;
            color: rgba(19, 28, 49, 0.5);
          }
        }
      }
    }
  }
}
.challengeWrap {
  position: relative;
  margin: 72px 0 64px;
  margin-left: calc(-50vw + 50%);
  width: 100vw;
  @media screen and (max-width: 850px) {
    margin-left: 0;
    width: 100%;
  }
  .sectionBanner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 400px;
    background-image: url('../../assets/home/challengeBanner.jpg');
    background-size: cover;
    background-position: center;
  }
  .sectionContent {
    padding: 64px 0 0;
    position: relative;
    z-index: 1;
    max-width: 850px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    & > h2 {
      color: #fff;
      font-size: 36px;
      font-weight: 700;
      padding: 10px 0;
    }
    & > p {
      padding-bottom: 26px;
      color: #fff;
      font-size: 20px;
      font-weight: 400;
      letter-spacing: -0.08px;
    }
    :global(.swiper) {
      width: 100%;
    }
    & > button {
      margin-top: 64px;
      padding: 16px 71px;
      border-radius: 45.5px;
      border: 1px solid #e5e5e5;
      color: rgba(19, 28, 49, 0.5);
      font-size: 15px;
      font-weight: 500;
      background-color: white;
      cursor: pointer;
    }
  }
}

.normalProductWrap {
  padding: 60px 21px;
  & > h3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin-bottom: 20px;
    span {
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      color: #131c31;
    }
    .directWrap {
      span {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #666;
      }
      img {
        width: 16px;
        height: 16px;
      }
    }
  }
  .productSlideWrap {
    cursor: pointer;
  }
  .productThumbnailWrap {
    img {
      background: #fbfbfb;
      border-radius: 10px;
      width: 100%;
      aspect-ratio: 1/1;
      object-fit: cover;
    }
  }
  .productInfoWrap {
    padding: 16px;
    .productInfoLeft {
      p {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
      }
      .productInfoPrice {
        display: flex;
        gap: 4px;
        align-items: center;
        span:first-child {
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
        }
        span:nth-child(2) {
          font-weight: 600;
          font-size: 10px;
          color: #999;
          text-decoration: line-through;
        }
      }
      .productInfoScore {
        display: flex;
        gap: 3px;
        align-items: center;
        img {
          width: 14px;
          height: 14px;
        }
        span {
          font-size: 12px;
        }
      }
    }
  }
  // & > span {
  //   font-weight: 400;
  //   font-size: 15px;
  //   line-height: 18px;
  //   letter-spacing: -0.004em;
  //   color: #131c31;
  //   display: inline-block;
  //   padding-bottom: 10px;
  // }
  // .normalProductList {
  //   display: flex;
  //   margin-top: 16px;
  //   li {
  //     padding-bottom: 14px;
  //     width: 100%;
  //     .productThumbnailWrap {
  //       width: 100%;
  //       border: 1px solid #e5e5e5;
  //       border-radius: 5px;
  //       overflow: hidden;
  //       aspect-ratio: 1/0.57;
  //       display: flex;
  //       justify-content: center;
  //       align-items: center;
  //       img {
  //         width: 148px;
  //         aspect-ratio: 1/1;
  //         object-fit: cover;
  //       }
  //     }
  //     .productInfoWrap {
  //       h3 {
  //         font-weight: 700;
  //         font-size: 15px;
  //         line-height: 18px;
  //         letter-spacing: -0.004em;
  //         color: #131c31;
  //         padding: 10px 0;
  //       }
  //       .hashtagList {
  //         padding-bottom: 10px;
  //         li {
  //           font-weight: 400;
  //           font-size: 12px;
  //           line-height: 14px;
  //           letter-spacing: -0.004em;
  //           color: rgba(19, 28, 49, 0.5);
  //         }
  //       }
  //     }
  //   }
  // }
}
.allProductList {
  padding: 0 21px 32px;
  & > h3 {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #131c31;
    padding: 10px 0;
  }
  & > span {
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.004em;
    color: #131c31;
    display: inline-block;
    padding-bottom: 10px;
  }
  .productListWrap {
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    li {
      width: calc(50% - 10px);
      padding-bottom: 20px;
      .productThumbnailWrap {
        width: 100%;
        border: 1px solid #e5e5e5;
        border-radius: 5px;
        overflow: hidden;
        img {
          width: 100%;
          aspect-ratio: 1/1.2;
          object-fit: cover;
        }
      }
      .productInfoWrap {
        display: flex;
        flex-direction: column;
        & > h3 {
          font-weight: 700;
          font-size: 15px;
          line-height: 18px;
          letter-spacing: -0.004em;
          color: #131c31;
          padding: 10px 0;
        }
        .discountRate {
          font-weight: 700;
          font-size: 16px;
          line-height: 19px;
          letter-spacing: -0.05em;
          color: #ff5656;
          display: inline-block;
          padding: 0.5px 0;
        }
        .price {
          font-weight: 700;
          font-size: 15px;
          line-height: 18px;
          letter-spacing: -0.05em;
          color: #131c31;
          display: inline-block;
          padding: 1px 0;
        }
        .reviewScore {
          display: flex;
          align-items: center;
          gap: 3px;
          height: 20px;
          img {
            width: 14px;
            height: 14px;
          }
          span {
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            letter-spacing: -0.05em;
            color: #131c31;
          }
        }
      }
    }
  }
  button {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: rgba(19, 28, 49, 0.5);
    margin: 0 auto;
    border: 1px solid #e5e5e5;
    background-color: white;
    border-radius: 45.5px;
    width: 157px;
    height: 50px;
    display: block;
    margin-top: 32px;
  }
}
.dietBannerWrap {
  padding: 0 21px;
  margin-bottom: 60px;
  img {
    width: 100%;
    aspect-ratio: 333/320;
  }
}
.dietProgramWrap {
  padding: 0 21px 60px;
  .titleWrap {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 20px;
  }
  .dietSwiperWrap {
    .dietSliderWrap {
      .dietImgWrap {
        img {
          width: 100%;
        }
      }
      .dietContentWrap {
        padding: 16px;
        & > span:first-child {
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
        .priceWrap {
          display: flex;
          align-items: center;
          gap: 4px;
          & > span:first-child {
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
          }
          & > span:nth-child(2) {
            font-weight: 600;
            font-size: 10px;
            line-height: normal;
            color: #999;
            text-decoration: line-through;
          }
        }
        .starWrap {
          display: flex;
          gap: 3px;
          img {
            width: 14px;
            height: 14px;
          }
          span {
            font-weight: 400;
            font-size: 12px;
            line-height: normal;
          }
        }
      }
    }
  }
}
.faqWrap {
  padding: 0px 21px;
  margin-bottom: 60px;
  & > h3 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    .directWrap {
      span {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #666;
      }
      img {
        width: 16px;
        height: 16px;
      }
    }
  }
  & > span {
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.004em;
    color: #131c31;
    display: inline-block;
    padding-bottom: 10px;
  }
  & > ul {
    padding-top: 32px;
    display: flex;
    flex-direction: column;
  }
}

.reviewWrap {
  margin-bottom: 60px;
  & > h3 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 21px;
    cursor: pointer;
    .directWrap {
      span {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #666;
      }
      img {
        width: 16px;
        height: 16px;
      }
    }
  }
  & > span {
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.004em;
    color: #131c31;
    display: inline-block;
    padding-bottom: 10px;
    padding-left: 21px;
  }
  .reviewSliderWrap {
    margin-top: 32px;
    padding-left: 21px;
    position: relative;
    border-radius: 0;
  }
}
.globalLegitScriptWrap {
  padding-left: 21px;
  margin-bottom: 60px;
  img {
    width: 100%;
    object-fit: 333/182;
  }
  .legitWrap {
    position: relative;
    .legitLogo {
      position: absolute;
      right: 9%;
      bottom: 15%;
      width: 15%;
      aspect-ratio: 1/1;
      object-fit: cover;
    }
  }
}
.legitScript {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;
  position: relative;
  & > img:first-child {
    width: 100%;
    aspect-ratio: 1/0.74;
    object-fit: cover;
  }
  & > div {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img:first-child {
      width: 80px;
      height: 79px;
      object-fit: cover;
    }
    p:nth-child(2) {
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: #ffffff;
      padding: 17px 0 10px;
    }
    p:last-child {
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      color: #ffffff;
    }
  }
}
.ondocInfoWrap {
  padding: 0 21px;
  margin-bottom: 60px;
  & > h3 {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 20px;
  }
  ul {
    display: flex;
    flex-direction: column;
    gap: 10px;
    li:first-child {
      aspect-ratio: 333/256;
      img {
        position: absolute;
        top: 12px;
        right: 12px;
        width: 126px;
        aspect-ratio: 126/90;
        object-fit: cover;
      }
    }
    li:nth-child(2) {
      aspect-ratio: 333/216;
      img {
        position: absolute;
        top: 11px;
        right: 24px;
        width: 101px;
        aspect-ratio: 101/91;
        object-fit: cover;
      }
    }
    li:last-child {
      aspect-ratio: 333/216;
      img {
        position: absolute;
        top: 22px;
        right: 31px;
        width: 86px;
        aspect-ratio: 86/68;
        object-fit: cover;
      }
    }
    li {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      background: #fbfbfb;
      border-radius: 10px;
      box-sizing: border-box;
      padding: 0 24px;
      p:first-child {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #3259e6;
        margin-bottom: 8px;
      }
      h3 {
        font-weight: 600;
        font-size: 17px;
        line-height: 24px;
        color: #131c31;
        margin-bottom: 12px;
      }
      p:nth-child(3) {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #666;
      }
    }
  }
}
.doctorInfoWrap {
  padding: 0 21px;
  margin-bottom: 60px;
  & > h3 {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #131c31;
    margin-bottom: 20px;
  }
  .doctorNameWrap {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fbfbfb;
    border-radius: 10px;
    .doctorNameLeft {
      display: flex;
      align-items: center;
      img {
        width: 60px;
        height: 60px;
        aspect-ratio: 1/1;
      }
      .doctorName {
        p:first-child {
          font-weight: 600;
          font-size: 17px;
          line-height: 24px;
        }
        p:last-child {
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: #666;
        }
      }
    }
    & > img {
      width: 24px;
      height: 24px;
    }
  }
  .doctorInfoWrap {
    padding: 16px 33px;
    display: flex;
    gap: 33px;
  }
  // & > p {
  //   font-weight: 400;
  //   font-size: 14px;
  //   line-height: 17px;
  //   color: #131c31;
  //   padding: 4px 21px;
  // }

  // .doctorSliderWrap {
  //   position: relative;
  //   .doctorSlider {
  //     margin-top: 32px;
  //     padding: 0 21px;
  //     .doctorSlide {
  //       .doctorImageWrap {
  //         width: 100%;
  //         border-radius: 16px;
  //         background-color: white;
  //         overflow: hidden;
  //         display: flex;
  //         justify-content: center;
  //         & > img {
  //           width: 100%;
  //           max-width: 224px;
  //           height: 259px;
  //           max-height: 259px;

  //           object-fit: cover;
  //           &.bigImage {
  //             object-position: top;
  //           }
  //         }
  //       }
  //       .doctorInfo {
  //         margin-top: 32px;
  //         & > h4 {
  //           font-weight: 800;
  //           font-size: 20px;
  //           line-height: 27px;
  //           color: #131c31;
  //           text-align: center;
  //           padding: 3px 0 1px;
  //         }
  //         & > span {
  //           font-weight: 400;
  //           font-size: 14px;
  //           line-height: 17px;
  //           color: #131c31;
  //           display: inline-block;
  //           padding-bottom: 2px;
  //           width: 100%;
  //           text-align: center;
  //         }
  //         & > ul {
  //           display: flex;
  //           flex-direction: column;
  //           align-items: center;
  //           padding: 8px 0;
  //           li {
  //             font-weight: 400;
  //             font-size: 14px;
  //             line-height: 17px;
  //             color: #131c31;
  //           }
  //         }
  //       }
  //     }
  //   }
  //   .doctorSliderPagination {
  //     position: absolute;
  //     bottom: 186px;
  //     left: 50%;
  //     transform: translateX(-50%);
  //     height: 32px;
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //     li {
  //       width: 12px;
  //       height: 12px;
  //       display: flex;
  //       justify-content: center;
  //       align-items: center;
  //       position: relative;
  //       div {
  //         width: 4px;
  //         height: 4px;
  //         background-color: #131c31;
  //         border-radius: 50%;
  //         position: absolute;
  //         top: 50%;
  //         left: 50%;
  //         transform: translate(-50%, -50%);
  //       }
  //       &::after {
  //         transition: all 0.2s;
  //         scale: 0;
  //         content: '';
  //         display: block;
  //         width: 8px;
  //         height: 8px;
  //         border: 1px solid #131c31;
  //         box-sizing: border-box;
  //         border-radius: 50%;
  //         transform-origin: center center;
  //       }
  //       &.hit::after {
  //         scale: 1;
  //       }
  //     }
  //   }
  // }
}

.ondocService {
  position: relative;
  background: #fbfbfb;
  aspect-ratio: 375/232;
  .textWrap {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translate(0%, -50%);
    display: flex;
    gap: 12px;
    flex-direction: column;
    & > p:first-child {
      font-weight: 600;
      font-size: 17px;
      line-height: 20px;
      color: #131c31;
      text-align: center;
    }
    & > p:last-child {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: #131c31;
    }
  }
}
