.container {
  &.isDesktop {
    .contentWrap {
      min-height: calc(100vh - 245px);
    }
  }
  .contentWrap {
    background: #f9f9f9;
    .topWrap {
      padding: 20px 33px 20px 37px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #fff;
      & > span {
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        color: #3259e6;
      }
      div {
        display: flex;
        gap: 4px;
        span {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
        }
        span:first-child {
          color: #666;
        }
      }
    }
    .detailWrap {
      padding: 21px 21px 16px;
      .detailListFrame {
        padding: 21px 16px 16px;
        border-radius: 10px;
        background: #fff;
        .title {
          padding: 0 0 20px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          span:first-child {
            font-weight: 600;
            font-size: 17px;
            line-height: 24px;
          }
          .priceWrap {
            display: flex;
            align-items: center;
            span {
              color: #666;
              font-weight: 400;
              font-size: 12px;
              line-height: 16px;
            }
            img {
              cursor: pointer;
              width: 16px;
              height: 16px;
            }
          }
        }
        .subInfoWrap {
          padding: 16px;
          background: #edf8ff;
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 8px;
          .hit {
            font-weight: 600;
            font-size: 17px;
            line-height: 24px;
            color: #3259e6;
          }
          & > div {
            display: flex;
            gap: 4px;
            span {
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
            }
            span:first-child {
              color: #666;
            }
          }
        }
        .detailContainer {
          display: flex;
          gap: 11px;
          flex-direction: column;
          .detailFrame {
            padding: 14px 16px 16px;
            border-radius: 10px;
            background: #f6f7f9;
            .titlePriceWrap {
              padding: 0 0 16px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              border-bottom: 1px solid #d9d9d9;
              span:first-child {
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
              }
            }
            .listWrap {
              padding: 16px 0 0;
              display: flex;
              gap: 8px;
              flex-direction: column;
              .listFrame {
                display: flex;
                justify-content: space-between;
                span {
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 16px;
                  color: #666;
                }
              }
            }
          }
        }
      }
    }
    .deliveryBtnWrap {
      padding: 0 21px 16px;
      .deliveryBtn {
        cursor: pointer;
        display: flex;
        gap: 4px;
        justify-content: center;
        align-items: center;
        height: 54px;
        background: #fff;
        border-radius: 10px;
        img {
          width: 20px;
          height: 20px;
        }
        span {
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
    .cancelBtnWrap {
      padding: 0 21px 16px;
      .cancelBtn {
        cursor: pointer;
        display: flex;
        gap: 4px;
        justify-content: center;
        align-items: center;
        height: 54px;
        background: #999999;
        border-radius: 10px;
        img {
          width: 20px;
          height: 20px;
        }
        span {
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: #fff;
        }
      }
    }
    .noticeWrap {
      padding: 0 33px 24px;
      display: flex;
      gap: 8px;
      flex-direction: column;
      img {
        width: 16px;
        height: 16px;
      }
      span {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #999;
      }
    }
    .cancelWrap {
      padding: 0 33px 80px;
      span {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        text-decoration: underline;
      }
    }
  }
}
