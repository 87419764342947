.contentWrap {
  padding: 0 21px 80px;
  background: #f9f9f9;
  &.isDesktop {
    // padding: 28px 21px 465px;
    padding: 0 21px 465px;
    min-height: calc(100vh - 245px);
    box-sizing: border-box;
    .topWrap {
      padding: 28px 0 16px 12px;
      gap: 0;
      &.webTopWrap {
        padding: 0 0 16px;
        .rightWrap {
          padding: 21px 94px;
          width: 100%;
          .rightTitleWrap {
            margin-bottom: 4px;
          }
          .eatAlertImg {
            top: 21px;
            left: 21px;
            width: 52px;
            height: 52px;
          }
        }
      }
      .leftWrap {
        width: 228px;
        min-width: 228px;
        .alertTextWrap {
          span {
            font-size: 24px;
            line-height: 32px;
          }
        }
      }
      .rightWrap {
        width: 396px;
        .smallText {
          margin-bottom: 0;
        }
        .eatAlertImg {
          bottom: 12px;
        }
      }
    }
    .bannerWrap {
      img {
        aspect-ratio: 808/168;
      }
    }
    .dietListWrap {
      gap: 16px;
      flex-direction: row;
      .listFrame {
        width: 100%;
        .left {
        }
        .right {
        }
      }
    }
  }
  .topWrap {
    padding: 21px 0 16px 12px;
    display: flex;
    justify-content: space-between;
    gap: 16px;
    &.webTopWrap {
      padding: 0 0 21px;
      width: calc(100% + 42px);
      transform: translateX(-21px);
      .rightWrap {
        padding: 20px 33px;
        width: 100%;
        border: none;
        border-radius: 0;
        background: #fff;
        .title {
          font-size: 20px;
          line-height: 28px;
        }
        .rightTitleWrap {
          margin-bottom: 8px;
        }
        .smallText {
          margin-bottom: 0;
          span {
            font-size: 14px;
            line-height: 20px;
            color: #666;
          }
          .hitText {
            color: #131c31;
          }
        }
        .eatAlertImg {
          top: 20px;
          right: 33px;
          width: 48px;
          height: 48px;
        }
      }
    }
    .leftWrap {
      display: flex;
      gap: 8px;
      flex-direction: column;
      width: 111px;
      img {
        width: 16px;
        height: 16px;
      }
      .alertTextWrap {
        span {
          font-weight: 600;
          font-size: 20px;
          line-height: 28px;
          color: #3259e6;
        }
      }
    }
    .rightWrap {
      cursor: pointer;
      position: relative;
      padding: 20px;
      width: calc(100% - 111px);
      box-sizing: border-box;
      border-radius: 10px;
      background: #fff;
      border: 1px solid #3259e6;
      .rightTitleWrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 6px;
      }
      .title {
        font-weight: 600;
        font-size: 17px;
        line-height: 24px;
        color: #3259e6;
      }
      .smallText {
        margin-bottom: 12px;
        span {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
        }
        .hitText {
          font-weight: 600;
        }
      }
      .directWrap {
        span {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #3259e6;
        }
        img {
          width: 16px;
          height: 16px;
        }
      }
      .eatAlertImg {
        position: absolute;
        width: 40px;
        height: 40px;
        bottom: 20px;
        right: 20px;
      }
    }
  }
  .bannerWrap {
    img {
      cursor: pointer;
      width: 100%;
      aspect-ratio: 333/309;
    }
  }
  .dietListWrap {
    padding: 16px 0 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
    .listFrame {
      cursor: pointer;
      padding: 0 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #fff;
      border-radius: 10px;
      height: 80px;
      .left {
        display: flex;
        gap: 20px;
        align-items: center;
        img {
          width: 40px;
          height: 40px;
        }
        span {
          font-weight: 600;
          font-size: 17px;
          line-height: 24px;
        }
        .textWrap {
          display: flex;
          flex-direction: column;
          .smallText {
            font-weight: 600;
            font-size: 10px;
            line-height: 24px;
            color: #3259e6;
          }
        }
      }
      .right {
        .countWrap {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 24px;
          height: 24px;
          border-radius: 22px;
          background: #3259e6;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #fff;
        }
      }
    }
  }
}
