.gambiteaModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.7);
  .gambiteaContent {
    position: absolute;
    top: 50%;
    margin: 0 auto;
    transform: translateY(-50%);
    max-height: 90%;
    width: 100%;
    max-width: 850px;
    display: flex;
    flex-direction: column;
    .closeBtn {
      display: block;
      width: fit-content;
      margin-left: auto;
      padding: 14.5px 21px;
      font-weight: 700;
      font-size: 15px;
      line-height: 18px;
      color: #ffffff;
      cursor: pointer;
    }
    .detailImages {
      overflow: scroll;
      font-size: 0px;
      img {
        max-width: 100%;
      }
    }
  }
}
