.keyboardContainer {
  position: absolute;
  width: 100%;
  bottom: 100;
  .safeArea {
    height: env(safe-area-inset-bottom);
  }
}

.container {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  background-color: white;
  z-index: 999;
  .safeArea {
    height: env(safe-area-inset-bottom);
  }
}

.navibarUl {
  display: flex;
  height: 64px;
  border-top: 1px solid #efefef;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  padding: 0 35px;
  max-width: 850px;
  margin: 0 auto;
  @media screen and (max-width: 350px) {
    padding: 0;
  }
  li {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
    width: 65px;
    height: 100%;
    cursor: pointer;
    &.hit span {
      font-weight: 700;
    }
    img {
      width: 24px;
      height: 24px;
    }
    span {
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      color: #131c31;
      white-space: nowrap;
      @media screen and (max-width: 480px) {
        font-size: 10px;
      }
    }
  }
}

:global(.pc) .container ul {
  padding: 0 94px;
}
