.orderList {
  display: flex;
  flex-direction: column;
  min-height: 300px;
  li {
    &:first-child .orderBox {
      border-top: none;
    }
    .orderBox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      border-top: 10px solid #f6f7f9;
      padding: 32px 20px;
      .left {
        display: flex;
        align-items: center;
        gap: 12px;
        padding-bottom: 32px;
        .productImageWrap {
          border: 1px solid #e5e5e5;
          border-radius: 5px;
          width: 106px;
          height: 106px;
          box-sizing: border-box;
          overflow: hidden;
          flex-shrink: 0;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .productInfoWrap {
          display: flex;
          flex-direction: column;
          .productName {
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            letter-spacing: -0.5px;
            color: #232323;
            padding: 19px 4px 6px;
          }
          .optionInfo {
            display: flex;
            & > span {
              font-weight: 400;
              font-size: 13px;
              line-height: 16px;
              color: rgba(19, 28, 49, 0.5);
              display: inline-block;
              padding: 2px;
              // word-break: keep-all;
              // white-space: ;
              &:first-child {
                white-space: nowrap;
              }
            }
          }
          .pointInfo {
            display: flex;
            padding: 2px;
            gap: 10px;
            & > span {
              font-weight: 700;
              font-size: 13px;
              line-height: 16px;
              color: #131c31;
            }
          }
        }
      }
      button {
        border: 1px solid #131c31;
        border-radius: 5px;
        width: 100%;
        height: 40px;
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
        letter-spacing: -0.5px;
        color: #232323;
        background-color: white;
        box-sizing: border-box;
        cursor: pointer;
      }
    }
  }
}
.paginationWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
  & > img {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
  & > ul {
    display: flex;
    li {
      width: 27px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      letter-spacing: -0.5px;
      color: rgba(19, 28, 49, 0.3);
      cursor: pointer;
      &.hit {
        color: #131c31;
        font-weight: 600;
        position: relative;
        &::after {
          content: '';
          display: block;
          width: 20px;
          height: 1px;
          background-color: #131c31;
          position: absolute;
          bottom: -1px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }
}
:global(.pc) .orderList {
  gap: 20px;
  min-height: 300px;
  padding: 32px 20px 64px;
  li {
    .orderBox {
      padding: 0;
      border: none;
      .left {
        padding-bottom: 0px;
      }
      button {
        width: 106px;
      }
    }
  }
}
