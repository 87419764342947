.reviewList {
  min-height: 300px;
  li {
    padding: 32px 20px 0;
    border-bottom: 10px solid #f6f7f9;
    &:last-child {
      border: none;
    }
    .reviewBox {
      & > div:first-child {
        display: flex;
        justify-content: space-between;
        .reviewInfoWrap {
          display: flex;
          flex-direction: column;
          .productName {
            padding: 12.5px 0;
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            letter-spacing: -0.5px;
            color: #232323;
          }
          .optionWrap {
            display: flex;
            padding: 2px 0;
            span {
              font-weight: 400;
              font-size: 13px;
              line-height: 16px;
              color: rgba(19, 28, 49, 0.5);
              padding: 2px;
            }
          }
          .reviewerInfoWrap {
            display: flex;
            gap: 10px;
            padding: 10px 0;
            & > div {
              display: flex;
              align-items: center;
              gap: 10px;
              & > span:first-child {
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: #131c31;
              }
              & > span:last-child {
                font-weight: 700;
                font-size: 16px;
                line-height: 19px;
                color: #131c31;
              }
            }
          }
          .reviewScore {
            display: flex;
            align-items: center;
            padding: 10px 0;
            gap: 2px;
            & > span:first-child {
              width: 69px;
              font-weight: 400;
              font-size: 16px;
              line-height: 19px;
              color: #131c31;
            }
            .starWrap {
              display: flex;
              img {
                width: 24px;
                height: 24px;
              }
            }
            & > span:last-child {
              font-weight: 400;
              font-size: 16px;
              line-height: 19px;
              text-align: right;
              color: #131c31;
              width: 80px;
            }
          }
        }
        .right {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          .editBtns {
            padding: 2px 4px;
            display: flex;
            gap: 21px;

            & > span {
              font-weight: 400;
              font-size: 13px;
              line-height: 16px;
              color: rgba(19, 28, 49, 0.5);
              padding: 2px;
              cursor: pointer;
              &:first-child {
                position: relative;
                &::after {
                  content: 'l';
                  display: block;
                  font-weight: 400;
                  font-size: 13px;
                  line-height: 16px;
                  color: rgba(19, 28, 49, 0.5);
                  position: absolute;
                  right: -11px;
                  top: 50%;
                  transform: translateY(-50%);
                }
              }
            }
          }
          .reviewImageWrap {
            width: 106px;
            height: 106px;
            flex-shrink: 0;
            border: 1px solid #e5e5e5;
            box-sizing: border-box;
            position: relative;
            cursor: pointer;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
            .reviewImageCounter {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              background: linear-gradient(
                0deg,
                rgba(19, 28, 49, 0.7),
                rgba(19, 28, 49, 0.7)
              );
              font-weight: 400;
              font-size: 15px;
              line-height: 18px;
              color: #ffffff;
            }
          }
        }
      }
      .reviewContent {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #232323;
        padding: 12px 212px 56px 0;
      }
    }
  }
}
.paginationWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
  & > img {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
  & > ul {
    display: flex;
    li {
      width: 27px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      letter-spacing: -0.5px;
      color: rgba(19, 28, 49, 0.3);
      cursor: pointer;
      &.hit {
        color: #131c31;
        font-weight: 600;
        position: relative;
        &::after {
          content: '';
          display: block;
          width: 20px;
          height: 1px;
          background-color: #131c31;
          position: absolute;
          bottom: -1px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }
}
.reviewList.mobile {
  padding-top: 32px;
  li {
    padding-top: 0px;
    padding-bottom: 32px;
    .reviewImageWrap {
      width: 88px !important;
      height: 88px !important;
    }
    .reviewContent {
      padding: 12px 5px 14px !important;
    }
    .productName {
      font-size: 15px !important;
      padding: 7px 0 6px !important;
    }
    .reviewerInfoWrap {
      padding: 7.5px 0 !important;
      & > div span {
        font-size: 13px !important;
      }
    }
    .reviewScore {
      padding: 7.5px 0 !important;
      & > span:first-child {
        display: none;
      }
      .starWrap img {
        width: 16px !important;
        height: 16px !important;
      }
      & > span:last-child {
        width: 65px !important;
        font-size: 13px !important;
        line-height: 16px !important;
        color: rgba(19, 28, 49, 0.5) !important;
      }
    }
    .right {
      & > span:first-child {
        padding: 7.5px 6px !important;
      }
    }
  }
}
