.contentWrap {
  max-height: calc(100vh - 248px);
  &.isDesktop {
    max-height: calc(100vh - 317px);
    max-width: 850px;
    .chatWrap {
      .managerWrap {
        .chatContentFrame {
          .chatContentWrap {
            max-width: 500px;
          }
        }
      }
      .userWrap {
        .userChatContentFrame {
          .userChatContentWrap {
            max-width: 500px;
          }
        }
      }
    }
  }
  .dateWrap {
    padding: 6px 12px;
    margin: 0 auto;
    width: fit-content;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #666;
    border-radius: 19.5px;
    background: #f9f9f9;
    margin-bottom: 12px;
    margin-top: 21px;
    &.notInit {
      margin-bottom: 30px;
    }
  }
  .enterWrap {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #666;
    text-align: center;
    margin-bottom: 30px;
  }
  .chatOutWrap {
  }
  .chatWrap {
    padding: 0 21px;
    overflow-y: scroll;
    .managerWrap {
      display: flex;
      gap: 8px;
      flex-direction: column;
      margin-bottom: 30px;
      // &:last-child {
      //   margin-bottom: 0;
      // }
      .nameWrap {
        padding: 0 0 0 8px;
        font-weight: 400;
        font-size: 12px;
        line-height: normal;
        color: #999;
      }
      .chatContentFrame {
        display: flex;
        gap: 8px;
        .chatContentWrap {
          padding: 12px 16px;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          background: #f9f9f9;
          border-radius: 10px 10px 10px 0;
          box-sizing: border-box;
          max-width: 280px;
          white-space: pre-line;
          &.errWrap {
            border: 1px solid #ff5656;
            color: #ff5656;
          }
          .centerTimeWrap {
            font-weight: 400;
            font-size: 11px;
            line-height: 12px;
            color: #666;
          }
          .noticeTitleWrap {
            padding: 10px 10px 12px;
            display: flex;
            gap: 10px;
            flex-direction: column;
            background: #fff;
            margin-bottom: 20px;
            img {
              width: 68px;
              height: 17px;
            }
            span {
              font-weight: 600;
              font-size: 14px;
              line-height: normal;
              color: #3259e6;
            }
          }
          .noticeBtnWrap {
            cursor: pointer;
            padding: 10px 0;
            background: #ffd33c;
            border-radius: 5px;
            margin-top: 20px;
            text-align: center;
            span {
              font-weight: 600;
              font-size: 14px;
              line-height: 22px;
            }
          }
        }
        .dietFoodDetailWrap {
          color: #666;
          .dietFoodDetailImgWrap {
            img {
              width: 100%;
              aspect-ratio: 100/66;
            }
          }
        }
        .chatDateWrap {
          display: flex;
          flex-direction: column;
          justify-content: end;
          span {
            font-weight: 400;
            font-size: 12px;
            line-height: normal;
            color: #999;
          }
        }
      }
      .quesFrame {
        display: flex;
        gap: 4px;
        align-items: center;
        flex-wrap: wrap;
        .quesWrap {
          cursor: pointer;
          display: flex;
          gap: 4px;
          align-items: center;
          padding: 6px 16px;
          width: fit-content;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          border-radius: 17px;
          box-sizing: border-box;
          background: #fff;
          border: 1px solid #d9d9d9;
          img {
            width: 22px;
            height: 22px;
          }
          .termWrap {
            .bigText {
              font-weight: 600;
            }
          }
          &.hit {
            color: #f5b100;
            background: #fff8e2;
            border: 1px solid #f5b100;
          }
        }
        &.hit {
          & > div {
            color: #f5b100;
            background: #fff8e2;
            border: 1px solid #f5b100;
          }
        }
        .detailWrap {
          cursor: pointer;
          font-weight: 400;
          font-size: 12px;
          line-height: normal;
          color: #999;
          text-decoration: underline;
        }
      }
      .termsFrame {
        gap: 7px;
      }
      .termTextWrap {
        padding: 0 8px;
        max-width: 248px;
        box-sizing: border-box;
        span {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #999;
        }
      }
      .notOkWrap {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #ff5656;
      }
    }
    .userWrap {
      display: flex;
      gap: 8px;
      flex-direction: column;
      align-items: end;
      margin-bottom: 30px;
      // &:last-child {
      //   margin-bottom: 0;
      // }
      .userChatContentFrame {
        display: flex;
        gap: 8px;
        .userChatContentWrap {
          position: relative;
          padding: 12px 16px;
          width: fit-content;
          min-height: 44px;
          background: #f5b100;
          border-radius: 10px 10px 0 10px;
          box-sizing: border-box;
          max-width: 280px;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: #fff;
          white-space: pre-line;
        }
        .userChatDateWrap {
          display: flex;
          flex-direction: column;
          justify-content: end;
          text-align: right;
          span {
            font-weight: 400;
            font-size: 12px;
            line-height: normal;
            color: #999;
          }
        }
      }
    }
    .noticeWrap {
      position: relative;
      max-width: 300px;
      .noticeFrame {
        img {
          width: 100%;
          aspect-ratio: 1/1;
        }
      }
      .paginationWrap {
        position: absolute;
        bottom: 9px;
        left: 130px;
        display: flex;
        gap: 4px;
        z-index: 1;
        li {
          width: 6px;
          height: 6px;
          background-color: white;
          border-radius: 4px;
          transition: width 0.2s linear;
          cursor: pointer;
          &.hit {
            width: 18px;
          }
        }
      }
    }
  }
  .userInputWrap {
    padding: 10px 21px;
    position: fixed;
    bottom: 63px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    box-sizing: border-box;
    background-color: #fff;
    z-index: 9999;
    max-width: 850px;
    .userInputFrame {
      position: relative;
      textarea {
        padding: 16px 52px 16px 20px;
        width: 100%;
        height: 52px;
        max-height: 72px;
        border-radius: 26px;
        border: 1px solid #d9d9d9;
        box-sizing: border-box;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        resize: none;
        scrollbar-width: none;
        -ms-overflow-style: none;
      }
      textarea::-webkit-scrollbar {
        display: none;
      }
      textarea::placeholder {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }
      img {
        cursor: pointer;
        position: absolute;
        bottom: 8px;
        right: 8px;
        width: 36px;
        height: 36px;
      }
    }
  }
}
