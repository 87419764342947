.heighter {
  height: 52px;
}
.container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 52px;
  background-color: #fff500;
  z-index: 99999999;
  display: flex;
  justify-content: center;
  align-items: center;
  // gap: 15px;
  cursor: pointer;
  .productImageWrap {
    width: 106px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
    }
  }
  .productName {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    padding: 3px 4px;
    margin: 0 7px;
    white-space: nowrap;
  }
  .priceWrap {
    padding: 10px;
    display: flex;
    gap: 10px;
    flex-shrink: 0;
    .price {
      display: flex;
      flex-wrap: wrap;
      span {
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        color: rgba(0, 0, 0, 0.5);
        &:last-child {
          text-decoration: line-through;
        }
      }
    }
    .discountedPrice {
      display: flex;
      flex-wrap: wrap;
      span {
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .productImageWrap {
      width: 91px;
      height: 45px;
    }
    .productName {
      font-size: 12px;
      font-weight: 700;
      line-height: 14px;
    }
    .priceWrap {
      padding: 0 2px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 0;
      .price {
        span {
          font-size: 9px;
          font-weight: 500;
          line-height: 11px;
        }
      }
      .discountedPrice {
        padding: 2px;
        span {
          font-size: 12px;
          font-weight: 700;
          line-height: 14px;
        }
      }
    }
  }
}

.couponEvent {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 52px;
  background-color: #2d1f15;
  z-index: 99999999;
  display: flex;
  justify-content: center;
  cursor: pointer;
  img {
    width: 100%;
    height: 52px;
    object-position: center center;
    object-fit: cover;
  }
}
