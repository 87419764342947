.prevWrap {
  background-color: #fff;
  padding: 8px 21px;
}

.contentWrap {
  background-color: #f6f7f9;
  min-height: 50vh;
  padding: 50px 33px;
  &.isDesktop {
    padding: 50px 180px;

    .dateInputWrap {
      display: flex;
      button {
        min-width: 236px;
        padding: 15px 103px 15px 12px;
      }
    }
  }
  h3 {
    color: #232323;
    font-size: 24px;
    font-weight: 700;
    padding-bottom: 50px;
  }
  .dateInputWrap {
    button {
      min-width: 145px;
      border: none;
      color: #abb1bb;
      text-align: center;
      border-bottom: 1px solid #e5e5e5;
      font-size: 15px;
      font-weight: 400;
      background-color: #f6f7f9;
      padding: 15px 12px;
    }
    span {
      color: #131c31;
      font-size: 15px;
      font-weight: 400;
      margin: 15px 6px;
    }
  }
}

.inputBtn {
  border-radius: 2px;
  background: #131c31;
  color: #ffffff;
  font-size: 15px;
  font-weight: 500;
  width: 100%;
  height: 54px;
  border: none;
  margin-top: 20px;
}
