.contentWrap {
  max-width: 386px;
  margin: 0 auto;
  padding: 32px 21px 64px;
}
.title {
  padding: 42px 21px;
  img {
    width: 21px;
    height: 21px;
  }
  span {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #232323;
  }
}
.header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px 0;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  margin-bottom: 32px;
  span:first-child {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #232323;
    padding: 10px 0;
  }
  span:last-child {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #ff5656;
    padding: 10px 0;
  }
}

.pointListWrap {
  h4 {
    padding: 20px 0;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #131c31;
  }
  .pointList {
    display: flex;
    flex-direction: column;
    padding-bottom: 64px;
    li {
      display: flex;
      align-items: center;
      height: 90px;
      border-top: 1px solid #e5e5e5;
      padding: 0 17px;
      & > span:first-child {
        width: 73px;
        padding: 0 16px 0 0;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        color: rgba(19, 28, 49, 0.5);
      }
      & > span:nth-child(2) {
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        color: #131c31;
      }
      & > span:last-child {
        margin-left: auto;
        font-weight: 700;
        font-size: 15px;
        line-height: 18px;
        color: #ff5656;
        width: 69px;
        text-align: right;
        flex-shrink: 0;
      }
    }
  }
}
.paginationWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
  span {
    width: 27px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.5px;
    color: rgba(19, 28, 49, 0.3);
    cursor: pointer;
    &.hit {
      font-weight: 600;
      color: #131c31;
      position: relative;
      &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: -1px;
        left: 50%;
        width: 20px;
        border: 1px solid #131c31;
        transform: translateX(-50%);
      }
    }
  }
}
