.contentWrap {
  // padding: 0 20px;
}
h3.title {
  span {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #232323;
  }
  img {
    width: 24px;
    height: 24px;
  }
  padding: 42px 32px;
}
.categoryWrap {
  display: flex;
  gap: 12px;
  padding: 0 20px;
  // padding-bottom: 32px;
  li {
    border: 1px solid #efefef;
    border-radius: 5px;
    width: 175px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: rgba(19, 28, 49, 0.5);
    cursor: pointer;
    flex-grow: 1;
    &.hit {
      font-weight: 700;
      font-size: 15px;
      color: #131c31;
      border: 1px solid #131c31;
    }
  }
}

:global(.pc) .categoryWrap {
  // padding-bottom: 32px;
  li {
    flex-grow: 0;
  }
}
