.contentWrap {
  max-height: calc(100vh - 248px);
  &.isDesktop {
    max-height: calc(100vh - 317px);
    max-width: 850px;
    .chatWrap {
      .managerWrap {
        .chatContentFrame {
          .chatContentWrap {
            max-width: 500px;
          }
        }
        .quesFrame {
          max-width: 500px;
          .quesWrap {
            padding: 6px 15px;
          }
        }
        .termTextWrap {
          max-width: 500px;
        }
      }
      .userWrap {
        .userChatContentFrame {
          .userChatContentWrap {
            max-width: 500px;
            box-sizing: border-box;
          }
        }
      }
    }
  }
  .dateWrap {
    padding: 6px 12px;
    margin: 0 auto;
    width: fit-content;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #666;
    border-radius: 19.5px;
    background: #f9f9f9;
    margin-bottom: 12px;
    margin-top: 21px;
    &.notInit {
      margin-bottom: 30px;
    }
  }
  .enterWrap {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #666;
    text-align: center;
    margin-bottom: 30px;
  }
  .chatWrap {
    padding: 0 21px;
    overflow-y: scroll;
    .managerWrap {
      display: flex;
      gap: 8px;
      flex-direction: column;
      margin-bottom: 30px;
      // &:last-child {
      //   margin-bottom: 0;
      // }
      .nameWrap {
        padding: 0 0 0 8px;
        font-weight: 400;
        font-size: 12px;
        line-height: normal;
        color: #999;
      }
      .chatContentFrame {
        display: flex;
        gap: 8px;
        .chatContentWrap {
          padding: 12px 16px;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          background: #f9f9f9;
          border-radius: 10px 10px 10px 0;
          box-sizing: border-box;
          max-width: 280px;
          white-space: pre-line;
          &.errWrap {
            border: 1px solid #ff5656;
            color: #ff5656;
          }
        }
        .dietFoodDetailWrap {
          cursor: pointer;
          color: #666;
          .dietFoodDetailImgWrap {
            img {
              width: 101px;
              aspect-ratio: 101/87;
            }
          }
        }
        .chatDateWrap {
          display: flex;
          flex-direction: column;
          justify-content: end;
          span {
            font-weight: 400;
            font-size: 12px;
            line-height: normal;
            color: #999;
          }
        }
      }
      .quesFrame {
        display: flex;
        gap: 4px;
        row-gap: 8px;
        align-items: center;
        flex-wrap: wrap;
        .quesWrap {
          cursor: pointer;
          display: flex;
          gap: 4px;
          align-items: center;
          padding: 6px 16px;
          width: fit-content;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          border-radius: 17px;
          box-sizing: border-box;
          background: #fff;
          border: 1px solid #d9d9d9;
          &.noPointer {
            cursor: auto;
          }
          img {
            width: 22px;
            height: 22px;
          }
          .termWrap {
            .bigText {
              font-weight: 600;
            }
          }
          &.hit {
            color: #3259e6;
            background: #edf8ff;
            border: 1px solid #3259e6;
          }
        }
        &.hit {
          & > div {
            color: #3259e6;
            background: #edf8ff;
            border: 1px solid #3259e6;
          }
        }
        .detailWrap {
          cursor: pointer;
          font-weight: 400;
          font-size: 12px;
          line-height: normal;
          color: #999;
          text-decoration: underline;
        }
      }
      .termsFrame {
        gap: 7px;
      }
      .termTextWrap {
        padding: 0 8px;
        max-width: 248px;
        box-sizing: border-box;
        span {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #999;
        }
      }
      .notOkWrap {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #ff5656;
      }
    }
    .userWrap {
      display: flex;
      gap: 8px;
      flex-direction: column;
      align-items: end;
      margin-bottom: 30px;
      // &:last-child {
      //   margin-bottom: 0;
      // }
      .userChatContentFrame {
        display: flex;
        gap: 8px;
        .userChatContentWrap {
          position: relative;
          padding: 12px 16px;
          width: fit-content;
          min-height: 44px;
          background: #3259e6;
          border-radius: 10px 10px 0 10px;
          box-sizing: border-box;
          max-width: 280px;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: #fff;
          input {
            padding: 6px;
            width: 65px;
            box-sizing: border-box;
            background: #3259e6;
            border: none;
            color: #fff;
            border-bottom: 1px solid #7391ff;
            margin: 0 8px 0 4px;
          }
          .ageInput {
            width: 76px;
            padding: 6px 6px 6px 23px;
            margin: 0 0 0 4px;
          }
          .weightInput {
            margin: 0 0 0 4px;
          }
          .hateEtcInput {
            width: 216px;
            margin: 0 0 0 4px;
          }
          input::placeholder {
            color: #7391ff;
          }
          .unit1,
          .unit2,
          .ageUnit1,
          .ageUnit2 {
            position: absolute;
            color: #7391ff;
            bottom: 12px;
          }
          .ageUnit1 {
            right: 73px;
          }
          .ageUnit2 {
            right: 28px;
          }
          .unit1 {
            right: 139px;
          }
          .unit2 {
            right: 22px;
          }
        }
        .userInputContentWrap {
          padding: 7px 16px;
        }
        .userChatDateWrap {
          display: flex;
          flex-direction: column;
          justify-content: end;
          text-align: right;
          span {
            font-weight: 400;
            font-size: 12px;
            line-height: normal;
            color: #999;
          }
        }
      }
    }
    .inputBtnWrap {
      cursor: pointer;
      padding: 6px 16px;
      margin-left: auto;
      width: fit-content;
      border-radius: 17px;
      border: 1px solid #d9d9d9;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
    }
  }
}
