.container {
  background: #f6f7f9;
  &.isMobile {
    .mainWrap {
      padding: 0 21px 0;
      gap: 0;
      display: block;
      &.noMainWrap {
        padding: 0 21px 68px;
      }
      .headerWrap {
        padding: 18px 21px;
        width: 100%;
        transform: translateX(-21px);
        background: #fff;
        & > div {
          display: flex;
          justify-content: space-between;
          img {
            width: 24px;
            height: 24px;
          }
          span {
            font-weight: 600;
            font-size: 20px;
            line-height: 28px;
          }
        }
      }
      .nonMemList {
        padding: 21px 0 0;
        .inputListWrap {
          padding: 21px 0;
          border: 1px solid #d9d9d9;
          border-radius: 5px;
          background: #fff;
          .inputWrap {
            padding: 0 21px;
          }
        }
        .nonBtn {
          margin: 21px 0 40px;
        }
        .noDataWrap {
          padding: 32px 16px;
        }
      }
    }
  }
  .mainWrap {
    // padding: 60px 50px 463px;
    padding: 60px 50px 120px;
    display: flex;
    gap: 102px;
    .headerWrap {
      flex-shrink: 0;
      width: 148px;
      span {
        font-weight: 300;
        font-size: 40px;
        line-height: 60px;
        color: #131c31;
      }
    }
    .nonMemList {
      width: 100%;
      .inputListWrap {
        display: flex;
        gap: 16px;
        flex-direction: column;
        .inputWrap {
          display: flex;
          gap: 8px;
          flex-direction: column;
          span {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #666;
          }
          input {
            width: 100%;
            height: 55px;
            background: #fff;
            border-radius: 5px;
            box-sizing: border-box;
            outline: none;
            border: 1px solid #d9d9d9;
            padding-left: 10px;
            font-weight: 400;
            font-size: 14px;
            line-height: normal;
            color: #141736;
            &::placeholder {
              font-weight: 300;
              font-size: 14px;
              line-height: normal;
              color: #999;
            }
          }
          img {
            width: 19px;
            height: 20px;
            position: absolute;
            top: 50%;
            left: 29px;
            transform: translateY(-50%);
          }
        }
      }
      .nonBtn {
        cursor: pointer;
        width: 100%;
        height: 52px;
        box-sizing: border-box;
        background: #999999;
        border-radius: 8px;
        border: none;
        font-weight: 600;
        font-size: 17px;
        line-height: 24px;
        color: #ffffff;
        margin: 40px 0;
        &.nonOkBtn {
          background: #3259e6;
        }
      }
      .noDataWrap {
        padding: 24px 36px;
        display: flex;
        gap: 12px;
        align-items: center;
        background: #fff;
        border-radius: 8px;
        img {
          width: 78px;
          height: 78px;
          aspect-ratio: 1/1;
        }
        .textWrap {
          display: flex;
          gap: 4px;
          flex-direction: column;
          span:first-child {
            font-weight: 600;
            font-size: 17px;
            line-height: 24px;
            color: #131c31;
          }
          span:last-child {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #666;
          }
        }
      }
    }
  }
  .orderWrap {
    .header {
      padding: 21px;
      display: flex;
      align-items: center;
      height: 70px;
      box-sizing: border-box;
      background: #fff;
      img {
        width: 24px;
        height: 24px;
      }
      span {
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        color: #131c31;
      }
    }

    .orderInfoWrap {
      border-bottom: 1px solid #efefef;
      margin-bottom: 10px;
      .orderHeader {
        padding: 30px 21px 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        span:first-child {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          white-space: nowrap;
        }
        span:last-child {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #999999;
        }
      }
      .orderProductInfoWrap {
        background-color: white;
        .sectionHeader {
          padding: 16px 21px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 56px;
          box-sizing: border-box;
          border-bottom: 1px solid #f6f7f9;
          span:first-child {
            font-weight: 600;
            font-size: 17px;
            line-height: 24px;
            color: #131c31;
          }
          .orderCancelBtn,
          .deliveryCheck {
            margin-left: auto;
            margin-right: 7px;
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            color: rgba(19, 28, 49, 0.5);
            cursor: pointer;
          }
          .deliveryCheck {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 81px;
            height: 28px;
            color: #fff;
            border-radius: 100px;
            border: 1px solid #00be35;
            background: #00be35;
          }
          .status {
            border: 1px solid #121842;
            border-radius: 100px;
            width: 81px;
            height: 28px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
          }
        }
        .productWrap {
          padding: 16px 21px;
          display: flex;
          gap: 20px;
          .thumbnailWrap {
            width: 80px;
            height: 80px;
            overflow: hidden;
            border: 1px solid #e5e5e5;
            border-radius: 10px;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .right {
            display: flex;
            gap: 8px;
            flex-direction: column;
            .productName {
              font-weight: 600;
              font-size: 17px;
              line-height: 24px;
              color: #131c31;
            }
            .option {
              display: flex;
              gap: 4px;
              span:first-child {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #999;
                white-space: nowrap;
              }
              ul {
                display: flex;
                flex-direction: column;
                gap: 4px;
                li {
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 20px;
                  color: #131c31;
                }
              }
            }
            .count {
              display: flex;
              gap: 4px;
              span:first-child {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #999;
              }
              span:last-child {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #131c31;
              }
            }
            .deliveryNumber {
              display: flex;
              gap: 4px;
              span {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #999;
                &:first-child {
                  white-space: nowrap;
                }
              }
              span:last-child {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #131c31;
              }
            }
          }
        }
      }
    }
    .refundWrap {
      border-top: 1px solid #efefef;
      border-bottom: 1px solid #efefef;
      margin-bottom: 10px;
      background-color: white;
      padding: 0 21px;
      .refundHeader {
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: -0.5px;
        color: #232323;
        height: 50px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #efefef;
      }
      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: -0.5px;
        color: #999999;
        padding: 15px 15px 52px;
      }
    }

    .addressInfoWrap,
    .reservationInfoWrap {
      border-top: 1px solid #efefef;
      border-bottom: 1px solid #efefef;
      margin-bottom: 10px;
      background-color: white;
      padding: 0 21px;
      .addressHeader,
      .reservationHeader {
        display: flex;
        align-items: center;
        height: 56px;
        font-weight: 600;
        font-size: 17px;
        line-height: 24px;
        color: #131c31;
        border-bottom: 1px solid #efefef;
      }
      .addressInfo,
      .reservationInfo {
        padding: 17px 0 20px;
        display: flex;
        flex-wrap: wrap;
        row-gap: 8px;
        & > div {
          width: 100%;
          display: flex;
          gap: 51px;
          & > span:first-child {
            flex-basis: 49px;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #999999;
            white-space: nowrap;
            flex-shrink: 0;
          }
          & > span:last-child {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #131c31;
            display: flex;
            align-items: center;
            .mapBtn {
              margin-left: 6px;
              display: flex;
              align-items: center;
              cursor: pointer;
              span {
                color: #000;
                font-size: 15px;
                font-weight: 500;
              }
              img {
                width: 18px;
                height: 18px;
              }
            }
            .copyBtn {
              margin-left: 4px;
              cursor: pointer;
              display: flex;
              align-items: center;
              svg {
                width: 14px;
                height: 14px;
              }
              span {
                color: #ff5656;
                font-size: 15px;
                font-weight: 500;
              }
            }
          }
          &.half {
            width: 50%;
            @media screen and (max-width: 500px) {
              width: 100%;
            }
          }
        }
      }
    }
    .payInfoWrap {
      border-top: 1px solid #efefef;
      border-bottom: 1px solid #efefef;
      margin-bottom: 10px;
      background-color: white;
      padding: 0 21px;
      .payHeader {
        display: flex;
        align-items: center;
        height: 56px;
        font-weight: 600;
        font-size: 17px;
        line-height: 24px;
        color: #131c31;
        border-bottom: 1px solid #efefef;
      }
      .priceCalculation {
        padding: 16px 0;
        border-bottom: 1px solid #efefef;
        & > div {
          display: flex;
          justify-content: space-between;
        }
        & > div:first-child {
          margin-bottom: 8px;
          span:first-child {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #131c31;
          }
          span:last-child {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #131c31;
          }
        }
        & > div:nth-child(2) {
          margin-bottom: 8px;
          span:first-child {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #131c31;
          }
          span:last-child {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #131c31;
          }
        }
        & > div:nth-child(3) {
          span:first-child {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #131c31;
          }
          span:last-child {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #131c31;
          }
        }
        .discountInfoList {
          display: flex;
          flex-direction: column;
          li {
            margin-top: 4px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            & > div {
              display: flex;
              align-items: center;
              img {
                width: 20px;
                height: 20px;
              }
              span {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #999999;
              }
            }
            & > span {
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              color: #999999;
            }
          }
        }
      }
      .totalPrice {
        padding: 18px 0 16px;
        & > div {
          display: flex;
          justify-content: space-between;
          align-items: center;
          span:first-child {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #131c31;
          }
          span:last-child {
            font-weight: 600;
            font-size: 17px;
            line-height: 24px;
            color: #ff3d3d;
          }
        }
        & > span {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #999999;
        }
      }
    }
    .refundPriceWrap {
      border-top: 1px solid #efefef;
      border-bottom: 1px solid #efefef;
      margin-bottom: 10px;
      background-color: white;
      padding: 0 20px;
      .refundPriceHeader {
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: -0.5px;
        color: #232323;
        height: 50px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #efefef;
      }
      .refundCalculation {
        padding: 9px 16px 24px;
        border-bottom: 1px solid #efefef;
        & > div:first-child {
          display: flex;
          justify-content: space-between;
          span:first-child {
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            letter-spacing: -0.5px;
            color: #232323;
          }
          span:last-child {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            letter-spacing: -0.5px;
            color: #141736;
          }
        }
        .notRefundList {
          margin-top: 11px;
          display: flex;
          flex-direction: column;
          gap: 11px;
          li {
            display: flex;
            justify-content: space-between;
            span:first-child {
              font-weight: 400;
              font-size: 14px;
              line-height: 17px;
              letter-spacing: -0.5px;
              color: #999999;
            }
            span:last-child {
              font-weight: 400;
              font-size: 14px;
              line-height: 17px;
              letter-spacing: -0.5px;
              color: #999999;
            }
          }
        }
      }
      .totalRefundPrice {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 11px 15px 10px;
        span:first-child {
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          letter-spacing: -0.5px;
          color: #232323;
        }
        span:last-child {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          letter-spacing: -0.5px;
          color: #ff3d3d;
        }
      }
    }

    .payMethodWrap {
      border-top: 1px solid #efefef;
      border-bottom: 1px solid #efefef;
      background-color: white;
      padding: 0 21px;
      .payMethodHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 56px;
        border-bottom: 1px solid #efefef;
      }
      span {
        font-weight: 600;
        font-size: 17px;
        line-height: 24px;
        color: #131c31;
      }
      .payMethodListWrap {
        padding: 16px 0;
        display: flex;
        gap: 20px;
        flex-direction: column;
        span {
          padding: 0;
        }
        .paymethodFrame {
          display: flex;
          gap: 20px;
          .labelWrap {
            flex-shrink: 0;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #999;
          }
          .contentWrap {
            display: flex;
            justify-content: space-between;
            width: calc(100% - 20px);
            .leftWrap {
              display: flex;
              gap: 4px;
              flex-direction: column;
              span {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #131c31;
                @media screen and (max-width: 360px) {
                  width: 165px;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  overflow: hidden;
                }
              }
              span:nth-child(2) {
                color: #999;
              }
            }
            .rightWrap {
              span {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #131c31;
              }
            }
          }
        }
      }
    }

    .refundGuide {
      text-align: right;
      padding: 16px 21px;
      span {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #999999;
      }
    }
  }
}
