.reviewFilter {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(242, 242, 242, 0.67);
  backdrop-filter: blur(2.5px);
  border-radius: 10px;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  & > p {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #131c31;
    text-align: center;
    margin-bottom: 19px;
  }
  & > a {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #131c31;
    border-radius: 22px;
    width: 90px;
    height: 38px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.5px;
    color: #ffffff;
  }
}
