.dateSelectModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.7);
  .dateSelectContent {
    position: absolute;
    top: 50%;
    left: 15px;
    right: 15px;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    .closeBtn {
      display: block;
      width: fit-content;
      margin-left: auto;
      padding: 14.5px 21px;
      font-weight: 700;
      font-size: 15px;
      line-height: 18px;
      color: #ffffff;
      cursor: pointer;
    }
    .dateInfoWrap {
      background-color: white;
      padding: 23px 0 60px;
      // padding: 20px 21px;
      overflow: scroll;
      .dateHeaderWrap {
        padding: 20px 30px;
        // border-bottom: 1px solid #e5e5e5;
        span {
          margin: 0px 83px;
          color: #232323;
          text-align: center;
          font-size: 16px;
          font-weight: 600;
        }
        div {
          border-bottom: 1px solid #e5e5e5;
          min-width: 291px;
          margin-top: 10px;
          margin-bottom: 20px;
        }
      }
      .monthBtnWrap {
        // display: flex;
        // padding: 4px 10px;
        padding: 0px 28px 0px 28px;
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        button {
          width: 64px;
          height: 34px;
          padding: 4px 10px;
          border-radius: 5px;
          border: 1px solid #e5e5e5;
          background-color: #fff;
        }
        button:hover {
          color: #fff;
          background-color: #131c31;
        }
      }
      .dateSelectBtn {
        width: 100%;
        min-height: 54px;
        align-items: center;
        background: #131c31;
        color: #fff;
        border: none;
        color: #fff;
        text-align: center;
        font-size: 15px;
        font-weight: 500;
        letter-spacing: -0.5px;
        margin-bottom: -85px;
      }
    }
  }
}

:global(.pc) {
  .dateSelectModal {
    width: 850px;
    left: 50%;
    transform: translateX(-50%);
  }
  .dateSelectContent {
    width: 400px;
    left: 50%;
    transform: translate(-50%, -50%);
    .dateHeaderWrap {
      margin-left: 25px;
      div {
        margin-right: 20px;
      }
    }
    .monthBtnWrap {
      margin-left: 25px;
    }
  }
}
