.container {
  position: fixed;
  top: 0;
  left: 50%;
  bottom: 0;
  z-index: 1000;
  width: 100%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.5);
  &.isDesktop {
    max-width: 850px;
    .contentWrap {
      position: relative;
      top: 60%;
      left: 50%;
      transform: translate(-50%, -60%);
      width: 375px;
      height: 415px;
      border-radius: 24px;
      .closeWrap {
        position: absolute;
        right: 10px;
        top: -50px;
        img {
          cursor: pointer;
          width: 40px;
          height: 40px;
        }
      }
      .mainWrap {
        padding: 100px 0 30px;
        .imgWrap {
          bottom: 250px;
          img {
            width: 300px;
            aspect-ratio: 300/329;
          }
        }
      }
      .btnWrap {
        border-radius: 0 0 24px 24px;
      }
    }
  }
  .contentWrap {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 404px;
    box-sizing: border-box;
    border-radius: 24px 24px 0 0;
    background-color: #3259e6;
    .mainWrap {
      position: relative;
      padding: 100px 31px 29px;
      text-align: center;
      .imgWrap {
        position: absolute;
        bottom: 260px;
        left: 50%;
        transform: translateX(-50%);
        img {
          width: 250px;
          aspect-ratio: 303/329;
        }
      }
      .textWrap {
        .titleWrap {
          font-weight: 600;
          font-size: 24px;
          line-height: 32px;
          color: #fff;
        }
        .centerWrap {
          span {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #d9d9d9;
          }
          &.hit {
            color: #fff;
          }
        }
        .bottomWrap {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #fff;
        }
      }
    }
    .btnWrap {
      position: fixed;
      bottom: 0;
      padding: 10px 21px;
      width: 100%;
      height: 70px;
      box-sizing: border-box;
      background: #3259e6;
      .btn {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        border-radius: 8px;
        background: #fff;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #3259e6;
      }
    }
  }
}
