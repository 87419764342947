.container {
  position: fixed;
  top: 0;
  left: 50%;
  bottom: 0;
  z-index: 1000;
  width: 100%;
  max-width: 850px;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.4);
  // display: flex;
  // justify-content: center;
  // align-items: center;
  &.pc {
    min-width: 850px;
  }
}
.orderListWrap {
  background: #ffffff;
  border: 1px solid #131c31;
  border-radius: 5px;
  margin-bottom: 16px;
  & > div:first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 42px;
    padding: 0 12px;
    cursor: pointer;
    span {
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      color: #131c31;
    }
    img {
      width: 24px;
      height: 24px;
    }
  }
  ul {
    display: flex;
    flex-direction: column;
    li {
      padding: 10px 14px;
      border-top: 1px solid #131c31;
      display: flex;
      cursor: pointer;
      .productThumbnail {
        width: 73px;
        height: 73px;
        border: 1px solid #e5e5e5;
        border-radius: 5px;
        box-sizing: border-box;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .productInfoWrap {
        display: flex;
        flex-direction: column;
        .productName {
          font-weight: 600;
          font-size: 15px;
          line-height: 18px;
          letter-spacing: -0.5px;
          color: #232323;
          padding: 2px 4px;
        }
        .optionBox {
          padding: 1px 4px;
          display: flex;
          span {
            font-weight: 400;
            font-size: 15px;
            line-height: 18px;
            color: rgba(19, 28, 49, 0.5);
            padding: 2px;
          }
        }
      }
    }
  }
}
.contentWrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 672px;
  max-height: 70vh;
  padding-bottom: 64px;
  box-sizing: border-box;
  background-color: white;
  overflow: scroll;
  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 37px;
    background-color: #131c31;
    height: 50px;
    position: sticky;
    top: 0;
    z-index: 1;
    .left {
      padding: 0 6px;
      display: flex;
      gap: 10px;
      span {
        font-weight: 700;
        font-size: 13px;
        line-height: 16px;
        color: #ffffff;
      }
    }
    .closeBtn {
      padding: 10px 21.5px;
      font-weight: 700;
      font-size: 15px;
      line-height: 18px;
      color: #ffffff;
      cursor: pointer;
    }
  }
  .content {
    padding: 0 37px;
    .title {
      padding: 17px 12px 14px;
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      color: #232323;
      margin-bottom: 16px;
    }
    .productInfoWrap {
      display: flex;
      margin-bottom: 16px;
      .productThumbnail {
        width: 106px;
        height: 106px;
        border: 1px solid #e5e5e5;
        border-radius: 5px;
        box-sizing: border-box;
        overflow: hidden;
        flex-shrink: 0;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .productInfo {
        display: flex;
        flex-direction: column;
        .productName {
          font-weight: 600;
          font-size: 15px;
          line-height: 18px;
          letter-spacing: -0.5px;
          color: #232323;
          padding: 15.5px 4px;
        }
        .optionInfo {
          padding: 1px 4px;
          span {
            font-weight: 400;
            font-size: 15px;
            line-height: 18px;
            color: rgba(19, 28, 49, 0.5);
            padding: 2px;
          }
        }
      }
    }
    .userInfoWrap {
      display: flex;
      gap: 88px;
      margin-bottom: 16px;
      .gender {
        display: flex;
        flex-direction: column;
        & > span:first-child {
          font-weight: 700;
          font-size: 16px;
          line-height: 19px;
          color: #131c31;
          padding: 10px 0;
        }
        ul {
          display: flex;
          gap: 10px;
          li {
            border: 1px solid rgba(19, 28, 49, 0.2);
            border-radius: 100px;
            font-weight: 500;
            font-size: 12px;
            line-height: 14px;
            letter-spacing: -0.005em;
            color: rgba(19, 28, 49, 0.5);
            cursor: pointer;
            padding: 12px 18px;
            &.hit {
              background: #131c31;
              border: 1px solid rgba(19, 28, 49, 0.2);
              color: #ffffff;
            }
          }
        }
      }
      .age {
        display: flex;
        flex-direction: column;
        & > span {
          font-weight: 700;
          font-size: 16px;
          line-height: 19px;
          color: #131c31;
          padding: 10px 0;
        }
        ul {
          display: flex;
          gap: 10px;
          li {
            padding: 12px 18px;
            font-weight: 500;
            font-size: 12px;
            line-height: 14px;
            letter-spacing: -0.005em;
            color: rgba(19, 28, 49, 0.5);
            border: 1px solid rgba(19, 28, 49, 0.2);
            border-radius: 100px;
            cursor: pointer;
            &.hit {
              background: #131c31;
              border: 1px solid #e6e6e6;
              color: #ffffff;
            }
          }
        }
      }
    }
    .scoreWrap {
      display: flex;
      flex-direction: column;
      margin-bottom: 16px;
      & > span {
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #131c31;
        padding: 10px 0;
      }
      .customReviewList {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        li {
          width: 280px;
          height: 44px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          & > span {
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: #131c31;
          }
          .starWrap {
            display: flex;
            align-items: center;
            & > div {
              width: 24px;
              height: 24px;
              cursor: pointer;
              img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
    }
    .weightInfoWrap {
      display: flex;
      margin-bottom: 32px;
      & > span:first-child {
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #131c31;
        display: inline-block;
        padding: 9.5px 47px 9.5px 0;
      }
      .weight {
        width: 175px;
        height: 38px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;
        background: #ffffff;
        border: 1px solid #e5e5e5;
        border-radius: 5px;
        box-sizing: border-box;
        input {
          width: 60px;
          height: 26px;
          box-sizing: border-box;
          border: none;
          text-align: center;
          font-weight: 400;
          font-size: 15px;
          line-height: 18px;
          color: #131c31;
        }
        & > span {
          width: 24px;
          height: 26px;
          display: flex;
          justify-content: center;
          align-items: center;
          &:first-child {
            font-weight: 400;
            font-size: 15px;
            line-height: 18px;
            color: rgba(19, 28, 49, 0.5);
          }
          &:last-child {
            font-weight: 500;
            font-size: 15px;
            line-height: 18px;
            color: rgba(19, 28, 49, 0.5);
          }
        }
      }
    }
    .uploadImagesWrap {
      display: flex;
      flex-direction: column;
      & > span {
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #131c31;
        padding: 10px 0;
      }
      .uploadImageList {
        display: flex;
        gap: 10px;
        .selectedImageWrap {
          width: calc(100% - 20px);
          aspect-ratio: 1/1;
          position: relative;
          border: 1px solid #e5e5e5;
          box-sizing: border-box;
          & > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .closeBtn {
            width: 28px;
            height: 28px;
            position: absolute;
            right: 0;
            top: 0;
            img {
              width: 100%;
              height: 100%;
              cursor: pointer;
            }
          }
        }
      }
      .inputImgBtn {
        border: 1px solid #131c31;
        border-radius: 5px;
        height: 42px;
        position: relative;
        margin-top: 32px;
        overflow: hidden;
        cursor: pointer;
        input {
          width: 100%;
          height: 100%;
          position: relative;
          z-index: 1;
          opacity: 0;
        }
        .imgBtn {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: white;
          gap: 4px;
          img {
            width: 24px;
            height: 24px;
          }
          span {
            font-weight: 600;
            font-size: 15px;
            line-height: 18px;
            letter-spacing: -0.5px;
            color: #131c31;
          }
        }
      }
    }
    .reviewFormWrap {
      margin-top: 16px;
      .title {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 10px 0;
        img {
          width: 24px;
          height: 24px;
        }
        span {
          font-weight: 700;
          font-size: 16px;
          line-height: 19px;
          color: #131c31;
        }
      }
      textarea {
        resize: none;
        width: 100%;
        box-sizing: border-box;
        height: 133px;
        padding: 12px 10px;
        border: 1px solid rgba(19, 28, 49, 0.3);
        border-radius: 5px;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #131c31;
        &::placeholder {
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: rgba(35, 35, 35, 0.5);
        }
      }
    }
    .submitBtn {
      background: #131c31;
      border: 1px solid #131c31;
      border-radius: 5px;
      margin: 32px 0;
      width: 100%;
      height: 42px;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      letter-spacing: -0.5px;
      color: #ffffff;
      cursor: pointer;
    }
    .reviewGuideWrap {
      background: #f6f7f9;
      .guideTitle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 46px;
        padding: 0 10px;
        cursor: pointer;
        span {
          font-weight: 600;
          font-size: 15px;
          line-height: 18px;
          letter-spacing: -0.5px;
          color: #131c31;
        }
        img {
          width: 22px;
          height: 22px;
        }
      }
      .guideContent {
        padding: 12px 10px;
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        color: #131c31;
      }
    }
    .marketingAgreement {
      display: flex;
      // align-items: center;
      gap: 4px;
      width: fit-content;
      cursor: pointer;
      padding: 14.5px 1.5px;

      .marketingCheckbox {
        width: 15px;
        height: 15px;
        box-sizing: border-box;
        background: #ffffff;
        border: 1.5px solid #e5e5e5;
        border-radius: 3px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        &.isChecked {
          background-color: #3559ed;
          border: 1.5px solid #3559ed;
        }
        img {
          width: 9px;
          height: 7px;
        }
      }
      p {
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        color: #131c31;
      }
    }
  }
}
.mobile {
  top: 62px;
  .contentWrap {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: none;
    width: auto;
    max-height: none;
    .userInfoWrap {
      gap: unset;
      flex-wrap: wrap;
    }
    .content,
    .head {
      padding: 0 20px;
    }
  }
}
