.categorySelectWrap {
  padding: 88px 0 0 0px;
  & > h3 {
    text-align: center;
    font-weight: 700;
    font-size: 36px;
    line-height: 43px;
    letter-spacing: -0.5px;
    color: #141736;
    margin-bottom: 10px;
  }
  .selectProgramListWrap {
    &.isMobile {
      margin: 42px 21px;
      & > li {
        padding: 0 5px 0 0;
        .hospitalLeft {
          display: flex;
          align-items: center;
          gap: 5px;
          & > img {
            width: 90px;
          }
          .hospitalInfo {
            & > p {
              font-size: 16px;
            }
          }
        }
        & > img {
          width: 25px;
          height: 25px;
        }
      }
    }
    margin: 42px 0;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    & > li {
      border: 1px solid #efefef;
      border-radius: 10px;
      padding: 0 21px 0 0;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .hospitalLeft {
        display: flex;
        align-items: center;
        gap: 10px;
        & > img {
          width: 120px;
          aspect-ratio: 1/1;
          border-radius: 10px;
        }
        .hospitalInfo {
          display: flex;
          flex-direction: column;
          gap: 4px;
          & > p {
            font-weight: 600;
            font-size: 22px;
            letter-spacing: -0.5px;
            color: #232323;
          }
          .hospitalTag {
            display: flex;
            gap: 5px;
            & > span {
              padding: 3px 5px;
              border-radius: 3px;
              background-color: #e7eeff;
              color: #838a9b;
              font-size: 12px;
            }
          }
        }
      }
      & > img {
        width: 35px;
        height: 35px;
      }
    }
  }
}
