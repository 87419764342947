.contentWrap {
  position: relative;
  background: #f6f7f9;
  // min-height: calc(100vh - 175px);
  &.zeroContentWrap {
    background: #fff;
    .myCardsWrap {
      padding: 204px 0 369px;
    }
  }
  &.isMobile {
    .header {
      padding: 18px 21px;
      justify-content: space-between;
      height: 60px;
      background: #fff;
      div:last-child {
        width: 24px;
      }
    }
    .myCardsWrap {
      .noZeroCardWrap {
        .cardAddWrap {
          padding: 0 21px 21px;
        }
        .changeWrap {
          position: fixed;
          bottom: 64px;
          z-index: 100;
          background: #fff;
        }
      }
    }
  }
  .header {
    padding: 21px;
    display: flex;
    align-items: center;
    height: 70px;
    box-sizing: border-box;
    img {
      width: 24px;
      height: 24px;
    }
    span {
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      color: #131c31;
    }
  }
  .myCardsWrap {
    .noZeroCardWrap {
      .cardCountWrap {
        padding: 16px 21px 8px;
        display: flex;
        gap: 4px;
        align-items: center;
        span:first-child {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
        }
        span:nth-child(2) {
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: #3259e6;
        }
      }
      .cardListWrap {
        padding: 0 21px;
        display: flex;
        gap: 8px;
        flex-direction: column;
        margin-bottom: 16px;
        .cardListFrame {
          padding: 8px 16px 8px 8px;
          display: flex;
          flex-direction: column;
          gap: 8px;
          background: #fff;
          border-radius: 5px;
          .cardInfoWrap {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .leftWrap {
              display: flex;
              gap: 8px;
              align-items: center;
              img {
                width: 30px;
                height: 30px;
              }
              span {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
              }
            }
            .rightWrap {
              .deleteBtnWrap {
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 57px;
                height: 32px;
                border: 1px solid #d9d9d9;
                border-radius: 17px;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #999;
                &.selectBtn {
                  background: #3259e6;
                  color: #fff;
                }
              }
            }
          }
          .subscribeOutWrap {
            display: flex;
            flex-direction: column;
            gap: 8px;
            .subscribeWrap {
              padding: 16px;
              display: flex;
              gap: 8px;
              background: #edf8ff;
              border-radius: 10px;
              .subscribeMarkWrap {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 47px;
                height: 22px;
                background: #dae8fc;
                border-radius: 3px;
                font-weight: 600;
                font-size: 12px;
                line-height: 16px;
                color: #3259e6;
              }
              span {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: #3259e6;
              }
            }
          }
        }
      }
      .cardAddWrap {
        padding: 0 21px 200px;
        .cardAddBtnWrap {
          cursor: pointer;
          display: flex;
          gap: 8px;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 142px;
          background: #fff;
          border-radius: 5px;
          img {
            width: 30px;
            height: 30px;
          }
          span {
            font-weight: 500;
            font-size: 17px;
            line-height: 24px;
            color: #3259e6;
          }
        }
      }
      .changeWrap {
        padding: 10px 21px;
        width: 100%;
        height: 72px;
        box-sizing: border-box;
        .changeBtnWrap {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 52px;
          border-radius: 8px;
          font-weight: 600;
          font-size: 17px;
          line-height: normal;
          color: #fff;
          background: #999;
          &.okChange {
            background: #3259e6;
          }
        }
      }
    }
    .zeroCardWrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      img {
        width: 100px;
        height: 100px;
      }
      .zeroTextWrap {
        display: flex;
        gap: 4px;
        flex-direction: column;
        margin-bottom: 32px;
        .noCard {
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;
        }
        .noCardAlert {
          font-weight: 400;
          font-size: 17px;
          line-height: 20px;
          color: #999;
        }
      }
      .appendCardBtn {
        cursor: pointer;
        background: #3259e6;
        border-radius: 27px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 183px;
        height: 52px;
        span {
          font-weight: 600;
          font-size: 17px;
          line-height: normal;
          color: #ffffff;
        }
      }
    }
  }
}
