.container {
  cursor: pointer;
  & > img {
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
    border-radius: 16px;
  }
  .reviewInfoWrap {
    display: flex;
    flex-direction: column;
    padding-top: 5px;
    .userId {
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      color: #131c31;
      padding: 4px 10px;
    }
    .userInfo {
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      color: #abb1bb;
      padding: 4px 10px;
    }
    .starWrap {
      padding: 4px 10px;
      display: flex;
      margin-bottom: 5px;
      img {
        width: 22px;
        height: 22px;
      }
    }
    .content {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #131c31;
      padding: 20px 10px 0;
      margin-bottom: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      display: -webkit-box;
    }
  }
}

.productInfoWrap {
  padding: 8px 0 14px;
  margin: 0 8px;
  border-top: 1px solid #f8f9fd;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 11px;
  & > img {
    width: 50px;
    height: 50px;
    object-fit: cover;
  }
  .productInfo {
    display: flex;
    flex-direction: column;
    & > span:first-child {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #131c31;
      padding: 0 4px;
      margin: 4px 0;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    & > div:last-child {
      display: flex;
      gap: 19px;
      padding: 4px;
      .reviewCount,
      .reviewAvg {
        display: flex;
        gap: 2px;
        span:first-child {
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #abb1bb;
        }
        span:last-child {
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #131c31;
        }
      }
    }
  }
}

// 상품후기 게시판용 css
.boardPageFrame {
  border: 1px solid #f8f9fd;
  border-radius: 16px;
  overflow: hidden;
  & > img {
    border-radius: 0;
  }
}
// 상품후기 게시판용 css
